import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";

import styles from "./FastTable.module.scss";
import FastFilter from "./FastFilter";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Pagination from "../Pagination";
import { useSearchParams } from "react-router-dom";
import { createExcelFile } from "../../utils/Excel";
import FastGroups from "./FastGroups";
import groupData from "./groupData";
import filterData from "../filterData";
import sortData from "./sortData";
import calcTotals from "./calcTotals";
import { Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faArrowDown,
  faArrowUp,
  faChevronRight,
  faClose,
  faCreditCard,
  faEdit,
  faFileExcel,
  faGift,
  faLock,
  faMoneyBill,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { ORDER_DIR } from "../../utils/changeOrder";
import FastChart from "../FastChart/FastChart";
import getContrastColor from "../../utils/getContrastColor";
import FastPieChart from "../FastChart/FastPieChart";

export const VARIOUS_VALUES = "varios";

export const TABLE_TYPES = {
  boolean: "boolean",
  string: "string",
  currency: "currency",
  number: "number",
  date: "date",
  dateUnix: "dateUnix",
  dateUnixDate: "dateUnixDate",
  dateUnixTime: "dateUnixTime",
  image: "image",
  link: "link",
  color: "color",
  order: "order",
  select: "select",
  options: "select",
};

export const ICONS = {
  "money-bill": faMoneyBill,
  "credit-card": faCreditCard,
  gift: faGift,
  lock: faLock,
};

export default function FastTable({
  title = "",
  data,
  uniqueKey = "id",
  isLoading,
  isError,
  columns = [],
  updateSearchParams = true,
  groupedBy = [],
  showOnlyGrouped = false,
  showGroups = true,
  showFilters = true,
  showExcel = true,
  showPagination = true,
  chart,
  pieChart,
  onSelectRow,
  selected,
  SelectIcon = () => <FontAwesomeIcon icon={faChevronRight} />,
  onCreateMutation = null,
  onEditMutation = null,
  onDeleteMutation = null,
  cellStyle = (row, col) => ({}),
}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [ready, setReady] = useState(false);
  const [filters, setFilters] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [groupedPaginatedData, setGroupedPaginatedData] = useState([]);
  const [groups, setGroups] = useState(groupedBy);
  const [sortBy, setSortBy] = useState({ key: "", order: "" });
  const [editing, setEditing] = useState(null);
  const [editingRow, setEditingRow] = useState(null);

  const extraColumn = useMemo(
    () =>
      (typeof onSelectRow === "function" ? 1 : 0) +
      (!!onCreateMutation ? 1 : 0) +
      (!!onEditMutation ? 1 : 0) +
      (!!onDeleteMutation ? 1 : 0),
    [onCreateMutation, onDeleteMutation, onEditMutation, onSelectRow]
  );

  useEffect(() => {
    if (!ready) {
      if (updateSearchParams) {
        const urlParams = new URLSearchParams(searchParams);
        const sortKey = urlParams.get("s");
        const sortOrder = urlParams.get("o");
        if (sortKey && sortOrder) {
          setSortBy({ key: sortKey, order: sortOrder });
        }
      }
      setReady(true);
    }
  }, [searchParams, ready, updateSearchParams]);

  useEffect(() => {
    if (updateSearchParams) {
      const urlParams = new URLSearchParams(searchParams);
      if (sortBy.key && sortBy.order) {
        urlParams.set("s", sortBy.key);
        urlParams.set("o", sortBy.order);
      } else {
        urlParams.delete("s");
        urlParams.delete("o");
      }
      setSearchParams(urlParams);
    }
  }, [sortBy, setSearchParams, updateSearchParams, searchParams]);

  const parsedColumns = useMemo(() => {
    // console.log("rerender from parsedColumns");
    if (Array.isArray(data) && data.length > 0) {
      if (columns.length === 0) {
        return Object.keys(data[0]).map((key) => ({
          key,
          label: key,
          sort: true,
          filter: true,
          filterRender: columns[key]?.filterRender,
          filterValue: columns[key]?.filterValue,
        }));
      }
    }
    if (Array.isArray(columns) && columns.length > 0) {
      return columns;
    }
    return [];
  }, [data, columns]);

  const filteredData = useMemo(
    () => filterData(filters, data, columns),
    [data, filters, columns]
  );

  const totals = useMemo(
    () => calcTotals(filteredData, parsedColumns),
    [filteredData, parsedColumns]
  );

  const sortedData = useMemo(
    () => sortData(filteredData, parsedColumns, sortBy.key, sortBy.order),
    [filteredData, parsedColumns, sortBy.key, sortBy.order]
  );

  const columnsForFilter = useMemo(() => {
    return parsedColumns.filter((col) => col.filter);
  }, [parsedColumns]);

  const handleCreateExcelFile = () => {
    const fileName =
      title.replace(/\s/g, "_").toLowerCase() +
      "-" +
      Math.floor(Date.now() / 1000);
    createExcelFile(
      sortedData.map((row) => {
        return parsedColumns.reduce((acc, col) => {
          const key = col?.sourceKey ? col.sourceKey : col.key;
          acc[col.label] =
            typeof row[key] !== "undefined" ? renderCellString(col, row) : "";
          return acc;
        }, {});
      }),
      `${fileName}.xlsx`
    );
  };

  const groupedData = useMemo(
    () =>
      sortData(
        groupData(groups, filteredData, columns),
        parsedColumns,
        sortBy.key,
        sortBy.order
      ),
    [groups, filteredData, columns, parsedColumns, sortBy.key, sortBy.order]
  );

  const handleOnChangeOrder = (row, dir, col) => {
    const affectedRows = [];
    const orderKey = col.key;
    const originOrderValue = row[orderKey];
    // find
    let targetOrderValue;
    data.forEach((r, i) => {
      if (dir === ORDER_DIR.ASC) {
        if (r[orderKey] > originOrderValue) {
          if (!targetOrderValue || r[orderKey] < targetOrderValue) {
            targetOrderValue = r[orderKey];
          }
        }
      }
    });
    data.forEach((r, i) => {
      if (r[uniqueKey] === row[uniqueKey]) {
        affectedRows.push(r);
      }
    });
    col?.onChangeOrder(row, dir);
  };

  const handleOnEditRow = useCallback(
    (row, col, value) => {
      console.log("handleOnEditRow", editingRow, row, col, value);
      if (!editingRow) return;
      if (editingRow[col] === value) return;
      setEditingRow({ key: editingRow?.key, ...editingRow, [col]: value });
    },
    [editingRow]
  );
  const handleOnSaveEditRow = useCallback(() => {
    console.log("handleOnSaveEditRow", editingRow);
    if (!editingRow) return;
    const { key, ...values } = editingRow;
    onEditMutation.mutate(values);
    setEditingRow(null);
  }, [editingRow, onEditMutation]);

  const handleOnSaveCreateRow = useCallback(() => {
    console.log("handleOnSaveCreateRow", editingRow);
    if (!editingRow) return;
    const { key, ...values } = editingRow;
    onCreateMutation.mutate(values);
    setEditingRow(null);
  }, [editingRow, onCreateMutation]);

  // useEffect(() => {
  //   console.log("FastTable useEffect data", data);
  // }, [data]);

  // useEffect(() => {
  //   console.log("FastTable useEffect sortedData", sortedData);
  // }, [sortedData]);

  useEffect(() => {
    // console.log(
    //   "FastTable useEffect showPagination, sortedData",
    //   showPagination,
    //   sortedData
    // );
    if (!showPagination) {
      setPaginatedData(sortedData);
    }
  }, [showPagination, sortedData]);

  if (!ready) return null;

  return (
    <Container className={styles.FastTable} fluid={true}>
      {title && title !== "" && <h2>{title}</h2>}
      {(showFilters || (!showOnlyGrouped && showGroups) || showExcel) && (
        <Row className="mb-4">
          <Col>
            {showFilters && (
              <FastFilter
                data={data}
                setFilters={setFilters}
                columns={columnsForFilter}
                updateSearchParams={updateSearchParams}
              />
            )}
          </Col>
          <Col>
            {!showOnlyGrouped && showGroups && (
              <FastGroups
                data={filteredData}
                setGroups={setGroups}
                columns={parsedColumns}
              />
            )}
          </Col>
          <Col className={styles.TableHeader}>
            {showExcel && (
              <Button
                variant="secondary"
                style={{ fontSize: 14, fontWeight: 600 }}
                onClick={handleCreateExcelFile}
              >
                {/* STYLE_CHANGE icon-xlsx-white.svg */}
                Exportar a Excel{" "}
                <FontAwesomeIcon icon={faFileExcel} className="ms-2" />
              </Button>
            )}
          </Col>
        </Row>
      )}
      {chart && (
        <FastChart data={sortedData} columns={parsedColumns} {...chart} />
      )}
      {pieChart && (
        <FastPieChart data={sortedData} columns={parsedColumns} {...pieChart} />
      )}
      <Row>
        <Col className={`table-container ${styles.TableContainer}`}>
          {isLoading && <Spinner />}
          {isError && <Alert variant="danger">Error</Alert>}
          <Table className="table-hover">
            <thead>
              <tr>
                {parsedColumns.map(
                  (col, index) =>
                    col?.hidden !== true && (
                      <th key={index} className="text-center">
                        <div>
                          <span>{col.label}</span>
                          {col.sortable !== false && (
                            <SortButton
                              sortKey={col.key}
                              sortBy={sortBy}
                              setSortBy={setSortBy}
                            />
                          )}
                        </div>
                      </th>
                    )
                )}
                {extraColumn > 0 && (
                  <th
                    style={{
                      width: extraColumn * 33,
                    }}
                  />
                )}
              </tr>
            </thead>
            <tbody>
              {!showOnlyGrouped && (
                <>
                  {Array.isArray(paginatedData) &&
                    paginatedData.length > 0 &&
                    paginatedData.map((row, rowIndex) => (
                      <tr
                        key={rowIndex}
                        className={[
                          typeof selected === "function" && selected(row)
                            ? styles.SelectedRow
                            : "",
                          typeof onSelectRow === "function"
                            ? styles.SelectableRow
                            : "",
                        ].join(" ")}
                        onClick={(e) => {
                          if (typeof onSelectRow === "function") {
                            onSelectRow(row);
                          }
                        }}
                      >
                        {parsedColumns.map((col, colIndex) => {
                          const key = col.key;
                          const value = row[key];
                          if (col?.hidden === true) return null;
                          return (
                            <Cell
                              key={colIndex}
                              col={col}
                              value={value}
                              row={row}
                              onChangeOrder={handleOnChangeOrder}
                              editing={editing === `${rowIndex}-${colIndex}`}
                              setEditing={(doEdit) =>
                                setEditing(
                                  doEdit ? `${rowIndex}-${colIndex}` : null
                                )
                              }
                              editingRow={
                                editingRow !== null &&
                                editingRow?.key === rowIndex &&
                                col?.nonEditable !== true
                                  ? editingRow
                                  : null
                              }
                              onEditRow={handleOnEditRow}
                              style={cellStyle(row, col)}
                            />
                          );
                        })}
                        {extraColumn > 0 && (
                          <td className={styles.ActionsCell}>
                            <div>
                              {onEditMutation &&
                                (editingRow?.key === rowIndex ? (
                                  <>
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        console.log("setEditingRow", row);
                                        setEditingRow(null);
                                      }}
                                      variant="secondary"
                                    >
                                      <FontAwesomeIcon icon={faClose} />
                                    </Button>
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        console.log("setEditingRow", row);
                                        handleOnSaveEditRow();
                                      }}
                                      variant="secondary"
                                    >
                                      <FontAwesomeIcon icon={faSave} />
                                    </Button>
                                  </>
                                ) : (
                                  <>
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        console.log("setEditingRow", row);
                                        setEditingRow({
                                          key: rowIndex,
                                          ...row,
                                        });
                                      }}
                                      variant="secondary"
                                    >
                                      <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                  </>
                                ))}
                              {onDeleteMutation && (
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    console.log(row);
                                    onDeleteMutation.mutate(row);
                                  }}
                                  variant="secondary"
                                  className={styles.DeleteButton}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              )}
                              {typeof onSelectRow === "function" && (
                                <Button
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    onSelectRow(row);
                                  }}
                                  size="sm"
                                >
                                  <SelectIcon />
                                </Button>
                              )}
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  {onCreateMutation && (
                    <>
                      <tr>
                        {parsedColumns.map((col, colIndex) => {
                          const isEditingNew = editingRow?.key === "new";
                          const value =
                            isEditingNew && editingRow[col.key] !== "undefined"
                              ? editingRow[col.key]
                              : "";
                          if (col?.hidden === true) return null;
                          return (
                            <Cell
                              key={colIndex}
                              col={col}
                              value={value}
                              row={isEditingNew ? editingRow : {}}
                              editing={isEditingNew}
                              setEditing={(doEdit) =>
                                setEditing(doEdit ? `new-${colIndex}` : null)
                              }
                              editingRow={
                                editingRow !== null &&
                                editingRow?.key === "new" &&
                                col?.nonEditable !== true
                                  ? editingRow
                                  : null
                              }
                              onEditRow={handleOnEditRow}
                              style={cellStyle(editingRow, col)}
                            />
                          );
                        })}
                        <td className={styles.CreateCell}>
                          {editingRow?.key === "new" ? (
                            <>
                              <Button
                                onClick={(e) => {
                                  console.log("setEditingRow new");
                                  handleOnSaveCreateRow();
                                }}
                                variant="secondary"
                              >
                                {onCreateMutation?.isLoading ? (
                                  <Spinner />
                                ) : (
                                  <FontAwesomeIcon icon={faSave} />
                                )}
                              </Button>
                              <Button
                                onClick={(e) => setEditingRow(null)}
                                variant="secondary"
                              >
                                <FontAwesomeIcon icon={faClose} />
                              </Button>
                            </>
                          ) : (
                            <Button
                              onClick={(e) => {
                                console.log("setEditingRow new");
                                setEditingRow({
                                  key: "new",
                                  ...parsedColumns?.reduce((acc, col) => {
                                    acc[col.key] = col?.defaultValue || "";
                                    return acc;
                                  }, {}),
                                });
                              }}
                              variant="secondary"
                            >
                              <FontAwesomeIcon icon={faAdd} />
                            </Button>
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                  {showPagination && (
                    <tr>
                      <td
                        colSpan={Math.max(
                          parsedColumns.length +
                            (typeof onSelectRow === "function" ? 1 : 0),
                          1
                        )}
                      >
                        <Pagination
                          data={sortedData}
                          setData={setPaginatedData}
                          updateSearchParams={updateSearchParams}
                        />
                      </td>
                    </tr>
                  )}
                </>
              )}
              {Array.isArray(groupedPaginatedData) &&
                groupedPaginatedData.length > 0 &&
                groupedPaginatedData.map((row, index) => (
                  <tr key={index}>
                    {parsedColumns.map((col, index) => {
                      const key = col.key;
                      const value = row[key];
                      return (
                        <Cell
                          key={index}
                          col={col}
                          row={row}
                          value={value}
                          style={cellStyle(row, col)}
                        />
                      );
                    })}
                  </tr>
                ))}
              {groupedData?.length > 0 && (
                <tr>
                  <td
                    colSpan={Math.max(
                      parsedColumns.length +
                        (typeof onSelectRow === "function" ? 1 : 0),
                      1
                    )}
                  >
                    <Pagination
                      data={groupedData}
                      setData={setGroupedPaginatedData}
                      updateSearchParams={false}
                    />
                  </td>
                </tr>
              )}
              {parsedColumns.some((col) => col.totals) && (
                <tr>
                  {parsedColumns.map((col, index) =>
                    typeof totals[col.key] !== "undefined" ? (
                      <Cell
                        key={index}
                        row={totals}
                        col={col}
                        value={totals[col.key]}
                        style={{ fontWeight: "bold" }}
                      />
                    ) : (
                      <td key={index} style={{ fontWeight: "bold" }}>
                        {index === 0 ? "Totales" : ""}
                      </td>
                    )
                  )}
                  {(typeof onSelectRow === "function" ||
                    !!onCreateMutation ||
                    !!onEditMutation ||
                    !!onDeleteMutation) && <td></td>}
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

const renderCellString = (col, row) => {
  const key = col?.sourceKey ? col.sourceKey : col.key;
  if (typeof col?.render === "function") {
    const rendered = col.render(row);
    if (typeof rendered === "string") return rendered;
  }
  if (typeof row[key] === "undefined") return "";
  return col.type === TABLE_TYPES.date
    ? new Date(row[key]).toLocaleString()
    : col.type === TABLE_TYPES.dateUnix
    ? col?.hideDateZero === true && parseInt(row[key]) === 0
      ? ""
      : new Date(parseInt(row[key]) * 1000).toLocaleString()
    : col.type === TABLE_TYPES.dateUnixDate
    ? new Date(parseInt(row[key]) * 1000).toLocaleDateString()
    : col.type === TABLE_TYPES.dateUnixTime
    ? new Date(parseInt(row[key]) * 1000).toLocaleTimeString()
    : col.type === TABLE_TYPES.boolean
    ? row[key]
      ? "Sí"
      : "No"
    : // : col.type === TABLE_TYPES.currency
      // ? `$ ${parseFloat("0" + row[key]).toFixed(2)}`.replace(
      //     /\B(?=(\d{3})+(?!\d))/g,
      //     ","
      //   )
      row[key];
};

const Cell = ({
  row,
  col,
  value,
  style,
  onChangeOrder,
  editing,
  setEditing,
  editingRow,
  onEditRow,
}) => {
  const _style = useMemo(() => {
    const __style = {};
    if (col?.type === TABLE_TYPES.color) {
      __style.backgroundColor = value;
      __style.color = getContrastColor(value);
      __style.fontWeight = 700;
      __style.fontFamily = "monospace";
      __style.textTransform = "uppercase";
      __style.letterSpacing = "1px";
      __style.fontSize = 12;
    }
    return { ...__style, ...col?.style, ...style };
  }, [col?.style, col?.type, style, value]);

  return (
    <td
      className={
        col?.type === TABLE_TYPES.string || col?.type === TABLE_TYPES.options
          ? "text-start"
          : col?.type === TABLE_TYPES.image ||
            col?.type === TABLE_TYPES.color ||
            col?.style?.textAlign === "center"
          ? "text-center"
          : "text-end"
      }
      style={_style}
      title={JSON.stringify(value)}
      onClick={() => typeof setEditing === "function" && setEditing(true)}
    >
      {col.editable && editing ? (
        <EditableCell
          row={row}
          col={col}
          value={renderCellString(col, row)}
          onClose={(changed, newValue) => {
            if (changed) {
              col.setValue(row, newValue);
            }
            setEditing(false);
          }}
        />
      ) : onEditRow !== null && editingRow ? (
        <EditableCell
          row={row}
          col={col}
          value={renderCellString(col, row)}
          onChange={onEditRow}
        />
      ) : (
        <>
          {col.prefix && <Prefix prefix={col.prefix} row={row} />}
          {typeof col?.render === "function" && row ? (
            col.render(row)
          ) : col?.type === TABLE_TYPES.currency ? (
            <>{!isNaN(value) ? parseFloat(value).toLocaleString() : value}</>
          ) : col?.type === TABLE_TYPES.select ? (
            <>{col?.options.find((option) => option.value === value)?.label}</>
          ) : value !== "null" ? (
            value === VARIOUS_VALUES ? (
              VARIOUS_VALUES
            ) : col?.type === TABLE_TYPES.order ? (
              <ChangeOrderButtons
                position={value}
                onChangeOrder={(dir) => {
                  onChangeOrder(row, dir, col);
                }}
              />
            ) : col.type === TABLE_TYPES.image && value !== "" ? (
              <img src={value} alt="content" {...col.attr} />
            ) : (
              renderCellString(col, row)
            )
          ) : (
            ""
          )}
          {col.suffix && <span> {col.suffix}</span>}
        </>
      )}
    </td>
  );
};

const EditableCell = ({
  row,
  col,
  value: defaultValue,
  onClose = null,
  onChange = null,
}) => {
  const [value, setLocalValue] = useState(defaultValue);
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <div className={styles.EditingCell}>
      {onClose !== null && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose(false, defaultValue);
          }}
        >
          <FontAwesomeIcon icon={faClose} />
        </Button>
      )}
      {col?.type === TABLE_TYPES.select ? (
        <Form.Select
          ref={inputRef}
          onChange={(e) => {
            setLocalValue(e.target.value);
            if (typeof onChange === "function") {
              onChange(row, col.key, e.target.value);
            }
          }}
          value={value}
        >
          {col.options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </Form.Select>
      ) : (
        <input
          ref={inputRef}
          type="text"
          defaultValue={value}
          onChange={(e) => {
            setLocalValue(e.target.value);
            if (typeof onChange === "function") {
              onChange(row, col.key, e.target.value);
            }
          }}
        />
      )}
      {onClose !== null && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onClose(true, value);
          }}
        >
          <FontAwesomeIcon icon={faSave} />
        </Button>
      )}
    </div>
  );
};

const ChangeOrderButtons = ({ onChangeOrder, position }) => {
  return (
    <div className={styles.ChangeOrderButtons}>
      <span>{position}</span>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onChangeOrder(ORDER_DIR.ASC);
        }}
      >
        <FontAwesomeIcon icon={faArrowUp} />
      </Button>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          onChangeOrder(ORDER_DIR.DESC);
        }}
      >
        <FontAwesomeIcon icon={faArrowDown} />
      </Button>
    </div>
  );
};

const SortButton = ({ sortKey, sortBy, setSortBy }) => {
  const handleClick = () => {
    console.log(
      "SortButton",
      sortKey,
      sortBy?.order,
      "=>",
      sortBy.order === ORDER_DIR.ASC ? ORDER_DIR.DESC : ORDER_DIR.ASC
    );
    if (sortBy.key === sortKey) {
      setSortBy({
        key: sortBy.key,
        order: sortBy.order === ORDER_DIR.ASC ? ORDER_DIR.DESC : ORDER_DIR.ASC,
      });
    } else {
      setSortBy({ key: sortKey, order: ORDER_DIR.ASC });
    }
  };

  const activeUp = sortBy.key === sortKey && sortBy.order === ORDER_DIR.ASC;
  const activeDown = sortBy.key === sortKey && sortBy.order === ORDER_DIR.DESC;

  return (
    <button onClick={handleClick} className={styles.SortButton}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 10 8.6"
        style={{ enableBackground: "new 0 0 10 8.6" }}
        className={[
          styles.SortButtonUp,
          activeUp ? styles.SortButtonActive : "inactive",
        ].join(" ")}
      >
        <polygon points="5,0 0,8.6 10,8.6" />
      </svg>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 10 8.6"
        style={{ enableBackground: "new 0 0 10 8.6" }}
        className={[
          styles.SortButtonDown,
          activeDown ? styles.SortButtonActive : "inactive",
        ].join(" ")}
      >
        <polygon points="5,8.6 0,0 10,0" />
      </svg>
    </button>
  );
};

function Prefix({ prefix, row }) {
  return <span>{typeof prefix === "function" ? prefix(row) : prefix}</span>;
}
