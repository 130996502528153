import FastTable, { TABLE_TYPES } from "../../components/FastTable/FastTable";
import useDevices from "../../hooks/useDevices";
import { Col, Row } from "react-bootstrap";
import FastForm from "../../components/FastForm/FastForm";
import { useAuth } from "../../hooks/useAuth";
import { usePage } from "../../hooks/usePage";
import { useParams } from "react-router-dom";
import useLang from "../../lang/useLang";
import useEvents from "../../hooks/useEvents";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { es, enUS as en } from "date-fns/locale";

export default function Devices() {
  const { trans, lang } = useLang();
  usePage({ title: trans("devices.devices") });
  const { user } = useAuth();
  const { eventid } = useParams();
  const { devicesQuery, registerDeviceMutation, editDevice } = useDevices({
    eventid,
  });
  const { data: events } = useEvents({ offset: 0, limit: 100000 });

  return (
    <>
      <h1>{trans("devices.devices")}</h1>
      <p>{new Date(devicesQuery.dataUpdatedAt).toLocaleString()}</p>
      <Row>
        <Col md={3}>
          <FastForm
            mutation={registerDeviceMutation}
            fields={[
              { name: "orgid", type: "hidden", defaultValue: user?.orgid },
              { name: "name", type: "string", label: trans("devices.name") },
              { name: "deviceshorttag", label: trans("devices.shorttag") },
              { name: "serial", label: trans("devices.serial") },
            ]}
          />
        </Col>
        <Col md={9}>
          <FastTable
            {...devicesQuery}
            cellStyle={(row, col) => {
              if (!row?.androidid) {
                return { backgroundColor: "#eee", color: "#000" };
              }
              const diff = Date.now() / 1000 - parseInt(row?.lastconnection);
              if (diff > 60 * 15) {
                // 15 minutes
                return { backgroundColor: "#fee", color: "#d00000" };
              }
              if (diff > 60 * 5) {
                // 5 minutes
                return { backgroundColor: "#ffefe0", color: "#ff8000" }; // orange
              }
              if (diff > 60 * 2) {
                return { backgroundColor: "#ffffe0", color: "#d0c000" }; // yellow
              }
              if (diff > 60 * 1) {
                return { backgroundColor: "#f9ffe7", color: "#2da000" }; // lime green
              }
              return { backgroundColor: "#e0ffe0", color: "#008000" }; // green
              // }
            }}
            columns={[
              {
                key: "deviceid",
                label: trans("devices.id"),
                style: { width: 50 },
              },
              { key: "orgid", label: trans("devices.orgid"), filter: true },
              {
                key: "name",
                label: trans("devices.name"),
                filter: true,
                type: TABLE_TYPES.string,
                editable: true,
                setValue: (row, value) =>
                  editDevice({
                    orgid: row.orgid,
                    deviceid: row.deviceid,
                    name: value,
                    deviceshorttag: row.deviceshorttag,
                  }),
              },
              {
                key: "androidid",
                label: trans("devices.deviceid"),
                filter: true,
                type: TABLE_TYPES.string,
                editable: true,
                setValue: (row, value) =>
                  editDevice({
                    orgid: row.orgid,
                    deviceid: row.deviceid,
                    androidid: value,
                    deviceshorttag: row.deviceshorttag,
                  }),
              },
              {
                key: "serial",
                label: trans("devices.serial"),
                filter: true,
                type: TABLE_TYPES.string,
                editable: true,
                setValue: (row, value) =>
                  editDevice({
                    orgid: row.orgid,
                    deviceid: row.deviceid,
                    serial: value,
                  }),
              },
              {
                key: "eventid",
                label: trans("devices.event"),
                filter: true,
                type: TABLE_TYPES.options,
                options: [
                  {
                    value: "0",
                    label: trans("devices.chooseevent"),
                  },
                  ...(events || []).map((event) => ({
                    value: event.eventid,
                    label: event.name,
                  })),
                ],
                editable: true,
                setValue: (row, value) =>
                  editDevice({
                    eventid: value,
                    orgid: row.orgid,
                    deviceid: row.deviceid,
                    deviceshorttag: row.deviceshorttag,
                  }),
              },
              {
                key: "deviceshorttag",
                label: trans("devices.shorttag"),
                filter: true,
                type: TABLE_TYPES.string,
                editable: true,
                setValue: (row, value) =>
                  editDevice({
                    orgid: row.orgid,
                    deviceid: row.deviceid,
                    deviceshorttag: value,
                  }),
              },
              {
                key: "lastconnection",
                label: trans("devices.lastconnection"),
                type: TABLE_TYPES.dateUnix,
                hideDateZero: true,
                editable: false,
              },
              {
                key: "timeago",
                label: trans("devices.timeago"),
                type: TABLE_TYPES.dateUnix,
                editable: false,
                sortValue: (row) => -parseInt(row?.lastconnection || 0),
                render: (row) => {
                  if (parseInt(row?.lastconnection || 0) === 0) return "";
                  return formatDistanceToNow(
                    fromUnixTime(parseInt(row?.lastconnection || 0)),
                    { locale: lang === "es" ? es : en }
                  );
                },
              },
            ]}
          />
        </Col>
      </Row>
    </>
  );
}
