import { Link, useParams } from "react-router-dom";
import FastForm from "../../components/FastForm/FastForm";
import { Alert, Container, Row, Col } from "react-bootstrap";
import useUsers from "../../hooks/useUsers";
import { useMemo } from "react";
import { usePage } from "../../hooks/usePage";
import FastTable, { TABLE_TYPES } from "../../components/FastTable/FastTable";
import useEvents from "../../hooks/useEvents";
import useCurrencies from "../../hooks/useCurrencies";
import TabPanel from "../../components/TabPanel";
import useReports from "../../hooks/useReports";
import { TX_TYPES } from "../../constants";
import useLocations from "../../hooks/useLocations";
import { render } from "@testing-library/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faList,
} from "@fortawesome/free-solid-svg-icons";

export default function EditUser() {
  usePage({ title: "Editar usuario" });
  const params = useParams();
  const { currencies, paymentMethods } = useCurrencies();
  const { data: events } = useEvents();
  const userid = !isNaN(params?.userid) ? parseInt(params.userid) : null;
  const { locations } = useLocations({ eventid: params.eventid });

  const { data: users, editUserMutation } = useUsers();

  const [thisUserIndex, nextUserIndex, prevUserIndex] = useMemo(() => {
    if (!users) return [0, 0, 0];
    const thisUserIndex = users.findIndex(
      (product) => parseInt(product.userid) === parseInt(userid)
    );
    return [
      thisUserIndex,
      thisUserIndex < users.length - 1 ? thisUserIndex + 1 : 0,
      thisUserIndex > 0 ? thisUserIndex - 1 : users.length - 1,
    ];
  }, [users, userid]);

  const user = useMemo(() => {
    const found = users?.find((u) => parseInt(u.userid) === userid);
    if (found) {
      const userCurrencies = currencies.map((currency) => {
        const currIdKey = Object.keys(found).find(
          (key) =>
            /^currencyid\d$/.test(key) && found[key] === currency.currencyid
        );
        if (currIdKey) {
          const currencyIndex = parseInt(currIdKey.replace("currencyid", ""));
          if (currencyIndex) {
            return {
              ...currency,
              balance: found[`balance${currencyIndex}`],
              date: found[`date${currencyIndex}`],
              userid: found[`userid${currencyIndex}`],
              deviceid: found[`deviceid${currencyIndex}`],
            };
          }
        }
        return { ...currency, balance: 0 };
      });
      found.currencies = userCurrencies;
    }
    return found;
  }, [users, userid, currencies]);

  const reports = useReports({
    eventid: params.eventid,
    limit: 10000000,
    offset: 0,
    start: 1,
    tag: user?.tag
  });

  const txs = useMemo(() => {
    if (reports.parsedData && user?.tag) {
      return reports.parsedData.filter((tx) => tx.tag === user.tag);
      // .map((tx) => {
      //   const currency = currencies.find(
      //     (currency) => currency.currencyid === tx.currencyid
      //   );
      //   return {
      //     ...tx,
      //     name: currency?.name,
      //     symbol: currency?.symbol,
      //   };
      // })
    }
    return [];
  }, [reports.parsedData, user?.tag, currencies]);

  return (
    <Container fluid className="pt-4">
      <Row>
        <Col>
          {user?.block === "1" && (
            <Alert variant="danger" className="text-center">Este usuario está bloqueado</Alert>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>
            Usuario{" "}
            {user?.name ? user.name : user?.tag ? user.tag : params?.userid}
          </h1>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {users?.length > 1 && (
            <>
              <Link
                className="nav-link text-primary"
                to={`/${params?.eventid}/users/${users[prevUserIndex]?.userid}`}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </Link>
              <Link
                className="nav-link text-primary"
                to={`/${params?.eventid}/users`}
              >
                <FontAwesomeIcon icon={faList} />
              </Link>
              <Link
                className="nav-link text-primary"
                to={`/${params?.eventid}/users/${users[nextUserIndex]?.userid}`}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </>
          )}
        </Col>
      </Row>
      {!user ? (
        <Alert variant="danger">
          No existe el usuario que buscas{" "}
          <Link to={`/${params.eventid}/users`}>Volver</Link>
        </Alert>
      ) : (
        <Row key={user?.userid}>
          <Col>
            <h2>Editar datos de usuario</h2>
            <FastForm
              mutation={editUserMutation}
              sendText="Guardar cambios"
              postParseData={(values) => {
                const { repair, block, ...data } = values;
                if (repair) {
                  data.repair = "1";
                } else {
                  data.repair = "0";
                }
                if (block) {
                  data.block = "1";
                } else {
                  data.block = "0";
                }
                return data;
              }}
              fields={[
                {
                  name: "userid",
                  type: "hidden",
                  defaultValue: parseInt(userid),
                },
                {
                  name: "name",
                  defaultValue: user?.name,
                  label: "Nombre",
                  placeholder: "Nombre",
                  type: "text",
                },
                {
                  name: "email",
                  defaultValue: user?.email,
                  label: "Email",
                  placeholder: "Email",
                  type: "email",
                  attributes: {
                    autofill: "not-my-email",
                    id: "not-my-email",
                  },
                },
                {
                  name: "tag",
                  defaultValue: user?.tag,
                  label: "Tag",
                  placeholder: "Tag",
                  type: "text",
                },
                {
                  name: "shortag",
                  defaultValue: user?.shortag,
                  label: "Short Tag",
                  placeholder: "Short Tag",
                  type: "text",
                },
                {
                  name: "eventid",
                  defaultValue: user?.eventid,
                  label: "Evento",
                  type: TABLE_TYPES.options,
                  options: [
                    { label: "Elige un evento", value: 0 },
                    ...events.map((e) => ({
                      label: e.name,
                      value: e.eventid,
                    })),
                  ],
                },
                {
                  name: "repair",
                  defaultValue: user?.repair === "1",
                  label: "Reparar",
                  type: "checkbox",
                  style: { width: "auto", flex: "1" },
                },
                {
                  name: "block",
                  defaultValue: user?.block === "1",
                  label: "Bloquear",
                  type: "checkbox",
                  style: { width: "auto", flex: "1" },
                },
              ]}
            />
          </Col>
          <Col lg={8}>
            <h2>Estado NFC</h2>
            <FastTable
              data={user?.currencies || []}
              columns={[
                {
                  key: "currencyid",
                  label: "ID",
                  style: { width: 50 },
                },
                {
                  key: "name",
                  label: "Nombre",
                  type: TABLE_TYPES.string,
                },
                {
                  key: "balance",
                  label: "Balance",
                  type: TABLE_TYPES.currency,
                  prefix: (row) => (row?.symbol ? row.symbol : "$") + " ",
                },
                {
                  key: "date",
                  label: "Últ. actualización",
                  type: TABLE_TYPES.dateUnix,
                },
              ]}
              showExcel={false}
              showGroups={false}
              showFilters={false}
              showPagination={false}
            />

            <hr />
            <h2>Transacciones</h2>
            <FastTable
              data={txs || []}
              columns={[
                { key: "txid", label: "ID", type: TABLE_TYPES.string },
                {
                  key: "date",
                  label: "Fecha",
                  type: TABLE_TYPES.dateUnixDate,
                  filter: true,
                },
                { key: "date", label: "Hora", type: TABLE_TYPES.dateUnixTime },
                {
                  key: "deviceName",
                  label: "Dispositivo",
                  type: TABLE_TYPES.string,
                  filter: true,
                },
                {
                  key: "userName",
                  label: "Operario",
                  type: TABLE_TYPES.string,
                },
                // {
                //   key: "eventid",
                //   label: "Evento",
                //   type: TABLE_TYPES.options,
                //   options: events.map((e) => ({
                //     label: e.name,
                //     value: e.eventid,
                //   })),
                // },
                {
                  key: "type",
                  label: "Tipo",
                  type: TABLE_TYPES.options,
                  options: Object.values(TX_TYPES),
                },
                {
                  key: "currencyid",
                  label: "Moneda",
                  type: TABLE_TYPES.options,
                  options: currencies.map((c) => ({
                    label: c.name,
                    value: c.currencyid,
                  })),
                },
                {
                  key: "amount",
                  label: "Monto",
                  type: TABLE_TYPES.currency,
                  prefix: "$ ",
                  aggregate: (accTotal, row) => {
                    console.log("aggregate", accTotal, row?.txid, row.amount);
                    const multiplier =
                      row.type === TX_TYPES.sale.value ? -1 : 1;
                    return accTotal + parseFloat(row.amount) * multiplier;
                  },
                  aggregateInitialValue: 0,
                },
                // { key: "tag", label: "ag", type: TABLE_TYPES.string },
                // { key: "items", label: "items", type: TABLE_TYPES.string },

                {
                  key: "locationid",
                  label: "locationid",
                  type: TABLE_TYPES.options,
                  options: (locations || []).map((l) => ({
                    label: l.name,
                    value: l.locationid,
                  })),
                },
                {
                  key: "paymentmethodid",
                  label: "paymentmethodid",
                  type: TABLE_TYPES.options,
                  options: paymentMethods.map((pm) => ({
                    label: pm.name,
                    value: pm.paymentmethodid,
                  })),
                },
                {
                  key: "verified",
                  label: "Ver",
                  render: (row) => <div style={{ textAlign: "center" }}>{
                    row.verified === "1" ? "✔️" : "❓"}</div>,
                  type: TABLE_TYPES.string,
                  filter: true,
                  filterType: "includes",
                  filterRender: (row) => row.verified === "1" ? "✔️" : "❓",
                },
              ]}
              groupedBy={["currencyid"]}
              cellStyle={(row) => {
                if (row.verified === "0") {
                  return { color: "#ea0", backgroundColor: "#ffe" };
                }
                return "";
              }}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}
