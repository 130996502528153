import { ORDER_DIR } from "../../utils/changeOrder";

export default function sortData(data, columns, key, order) {
  if (Array.isArray(data)) {
    if (key) {
      const sorted = [
        ...data.sort((a, b) => {
          const column = columns.find((col) => col.key === key);
          let sortA = a[key];
          let sortB = b[key];
          if (column?.sortValue) {
            sortA = column.sortValue(a);
            sortB = column.sortValue(b);
          }
          if (!isNaN(sortA) && !isNaN(sortB)) {
            sortA = parseFloat(sortA);
            sortB = parseFloat(sortB);
          }
          // console.log(
          //   "sortA",
          //   sortA,
          //   "sortB",
          //   sortB,
          //   "order",
          //   order,
          //   order === ORDER_DIR.ASC
          //     ? sortA > sortB
          //       ? 1
          //       : -1
          //     : sortA < sortB
          //     ? 1
          //     : -1
          // );
          if (order === ORDER_DIR.ASC) {
            return sortA > sortB ? 1 : -1;
          }
          return sortA < sortB ? 1 : -1;
        }),
      ];
      return sorted;
    }
    return [...data];
  }
  return data;
}
