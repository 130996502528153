import { useEffect, useState } from "react";

export default function useSearchParamState(key, defaultValue = null) {
  const [value, setValue] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get(key);
    if (paramValue) {
      return paramValue;
    }
    return defaultValue;
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get(key);
    if (paramValue) {
      setValue(paramValue);
    } else if (defaultValue) {
      setValue(defaultValue);
    }
  }, [key, defaultValue]);

  return [value, setValue];
}