import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "./useAuth";
import { post } from "../utils/Api";
import { useGlobalAlert } from "./useGlobalAlert";
import { useParams } from "react-router-dom";
import usePaginatedQuery from "./usePaginatedQuery";

export default function useUsers({
  offset = 0,
  limit = 100000,
  filter = {},
  enabled = true,
} = {}) {
  const { user } = useAuth();
  const params = useParams();
  const queryClient = useQueryClient();
  const token = user?.token;
  const { setAlert } = useGlobalAlert();

  const usersQuery = usePaginatedQuery({
    queryKey: [
      "users",
      token,
      offset,
      limit,
      ...Object.keys(filter),
      ...Object.values(filter),
    ],
    queryFn: ({ offset, limit }) => {
      const body = {
        orgid: user.orgid,
        token,
        offset,
        limit,
        filter: filter ? JSON.stringify(filter) : "",
      };
      if (params?.eventid) body.eventid = params.eventid;
      return post("/users", body);
    },
    enabled: enabled && !!token && !!user?.orgid,
  });

  const createUserMutation = useMutation({
    mutationFn: (body) => post("/register", { ...body, token: user?.token }),
    onSuccess: () => {
      queryClient.invalidateQueries(["users", token, offset, limit]);
      setAlert({ variant: "success", message: "Usuario creado" });
    },
    onError: (error) => {
      queryClient.invalidateQueries(["users", token, offset, limit]);
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
            ? error.code
            : "Error desconocido",
      });
    },
  });

  const editUserMutation = useMutation({
    mutationFn: (body) => {
      const bodyClone = { ...body, token: user?.token };
      if (!body?.password || body.password === "") {
        delete bodyClone.password;
        delete bodyClone.repassword;
      }
      bodyClone.eventid = parseInt(bodyClone.eventid);
      bodyClone.userid = parseInt(bodyClone.userid);
      return post("/edituser", bodyClone);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["users", token, offset, limit]);
      setAlert({ variant: "success", message: "Usuario actualizado" });
    },
    onError: (error) => {
      queryClient.invalidateQueries(["users", token, offset, limit]);
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
            ? error.code
            : "Error desconocido",
      });
    },
  });

  return {
    ...usersQuery,
    user: usersQuery.data,
    createUserMutation,
    createUser: createUserMutation.mutate,
    editUserMutation,
    editUser: editUserMutation.mutate,
  };
}
