import { Form, Spinner } from "react-bootstrap";
import useEvents from "../hooks/useEvents";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useLang from "../lang/useLang";
import { useMemo } from "react";

const EVENT_STATUS_PAST = -1;
const EVENT_STATUS_PRESENT = 0;
const EVENT_STATUS_FUTURE = 1;

export default function EventSelector() {
  const { trans } = useLang();
  const { data, error, isLoading, isError } = useEvents();
  const { user } = useAuth();
  const { orgid } = user;
  const { eventid } = useParams();
  const navigate = useNavigate();

  // get the current route
  const { pathname } = useLocation();


  const filtered = useMemo(
    (event) =>
      data
        ?.map((event) => {
          const now = Math.floor(new Date().getTime() / 1000);
          let status = EVENT_STATUS_FUTURE;
          if (event.start > now) {
            status = EVENT_STATUS_FUTURE;
          } else if (event.end < now) {
            status = EVENT_STATUS_PAST;
          } else {
            status = EVENT_STATUS_PRESENT;
          }
          return { ...event, dateStatus: status };
        })
        .filter(
          (event) =>
            [
              EVENT_STATUS_FUTURE,
              EVENT_STATUS_PRESENT,
            ].includes(event.dateStatus)
        ),
    [data]
  );

  const handleOnChange = (e) => {
    if (isNaN(e.target.value)) {
      navigate(`/`);
      return;
    }
    console.log("pathname", pathname);
    const newEventid = parseInt(e.target.value);
    if (!eventid) {
      navigate(`/${newEventid}`);
    } else {
      // replace the current route with the selected eventid
      const newPath = pathname.replace(/^\/\d+(.*)/, `/${newEventid}$1`);
      console.log("newPath", newPath);
      navigate(newPath);
    }
  };

  if (isNaN(orgid)) return null;
  if (isLoading) return <Spinner animation="border" />;
  if (isError) return <span title={error?.message}>error</span>;
  if (!data) return null;
  return (
    <Form.Select
      aria-label={trans("nav.chooseevent")}
      onChange={handleOnChange}
      value={eventid || ""}
    >
      <option>{trans("nav.chooseevent")}</option>
      {filtered?.map((event) => (
        event?.eventid && !isNaN(event?.eventid) ?
          <option key={event.eventid} value={event.eventid}>
            {event.name}
          </option> : null
      ))}
    </Form.Select>
  );
}
