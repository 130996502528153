import { Outlet, useNavigate, useParams } from "react-router-dom";
import useLang from "../../lang/useLang";
import { Alert, Button, ButtonGroup, Col, Container, Row, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft, faChartPie } from "@fortawesome/free-solid-svg-icons";
import DateRange from "../../components/FastChart/DateRange";
import UpdateReportButton from "../../components/UpdateReportButton";
import { usePublicSummary } from "../../hooks/usePublicSummary";
import PinField from "../../components/PinField";
import useSearchParamState from "../../hooks/useSearchParamState";

export default function PublicLayout() {
  const { trans } = useLang();
  const { rtoken } = useParams();
  const navigate = useNavigate();
  const path = window.location.pathname;
  const [dateStart, setDateStart] = useSearchParamState("st");
  const [dateEnd, setDateEnd] = useSearchParamState("ed");

  const {
    event,
    dataUpdatedAt,
    allLocations,
    refetch,
    isLoading,
    isRefetching,
    validData,
    pin,
    setPin,
    dataQuery,
    dataStatus,
    dataError,
  } = usePublicSummary({
    start: dateStart,
    end: dateEnd,
    limit: 1000000000,
    offset: 0,
    rtoken,
  });

  if (
    isLoading ||
    pin === null ||
    dataStatus === "loading" ||
    dataStatus === "pending"
  ) {
    return (
      <Container>
        <Row>
          <Col className="p-4 text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Col>
        </Row>
      </Container>
    );
  }

  if (validData === null) {
    return (
      <Container>
        <Row>
          <Col>
            <Alert variant="danger" className="text-center">
              {trans("reports.url_not_valid")}
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }

  if (dataStatus === "error") {
    return (
      <Container>
        <Row>
          <Col className="my-4 text-center" lg={{ offset: 4, span: 4 }}>
            <h2 className="text-center">Introduzca el código de acceso</h2>
            <PinField
              defaultValue={pin}
              onChange={(value) => {
                setPin(value);
                setTimeout(() => dataQuery.refetch(), 100);
              }}
            />
            {dataError?.msg && (
              <Alert variant="danger" className="mt-4">
                {dataError?.msg}
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  return <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
    <ButtonGroup>
      <Button
        onClick={() => navigate(`/pub/${rtoken}/viewer${window.location.search}`)}
        variant={path.includes("/viewer") ? "primary" : "light"}
      >
        <FontAwesomeIcon icon={faChartPie} className="bi me-2" />
        {trans("nav.viewer")}
      </Button>
      {/* <Button
        onClick={() => navigate(`/pub/${rtoken}/txs${window.location.search}`)}
        variant={path.includes("/txs") ? "primary" : "light"}
      >
        <FontAwesomeIcon icon={faArrowRightArrowLeft} className="bi me-2" />
        {trans("nav.transactions")}
      </Button> */}
    </ButtonGroup>

    <Container fluid={true}>
      <Row>
        <Col>
          <h1 style={{ marginTop: 0 }}>{event?.name}</h1>
          <h2 style={{ display: "flex", gap: 4, flexWrap: "wrap" }}>
            {validData &&
              (validData?.length === 0
                ? "Todas las ubicaciones"
                : Object.values(allLocations)
                  .filter((location) =>
                    validData.includes(parseInt(location.locationid))
                  )
                  .map((location, index) => (
                    <span
                      key={index}
                      style={{
                        fontSize: 12,
                        backgroundColor: "#fff",
                        color: "var(--bs-primary)",
                        border: "1px solid var(--bs-primary)",
                        padding: "4px 12px",
                        borderRadius: 20,
                        textAlign: "center",
                      }}
                    >
                      {location.name}
                    </span>
                  )))}
          </h2>
        </Col>
        <Col className="justify-content-end align-items-center d-flex">
          <UpdateReportButton
            refetch={refetch}
            dataUpdatedAt={dataUpdatedAt}
            isLoading={isLoading}
            isRefetching={isRefetching}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4 text-center" lg={{ offset: 4, span: 4 }}>
          <DateRange
            type="datetime-local"
            start={dateStart}
            end={dateEnd}
            setStart={setDateStart}
            setEnd={setDateEnd}
            options={[
              { value: 60 * 60, label: trans("reports.last_1hr") },
              { value: 60 * 60 * 12, label: trans("reports.last_12hr") },
              { value: 60 * 60 * 24, label: trans("reports.last_24hr") },
              { value: 60 * 60 * 48, label: trans("reports.last_48hr") },
              // { value: 60 * 60 * 24 * 7, label: trans("reports.last_7d") },
              // { value: 60 * 60 * 24 * 30, label: trans("reports.last_30d") },
            ]}
          />
        </Col>
      </Row>
      {!!dateStart ? (
        <>
          <Outlet />
        </>
      ) : (
        <Row>
          <Col>
            <h2 className="text-center">Seleccione un rango de fechas</h2>
          </Col>
        </Row>
      )}
    </Container>
  </div>;
}