import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { get, post } from "../utils/Api";
import { useMemo } from "react";
import { useGlobalAlert } from "./useGlobalAlert";
import { useAuth } from "./useAuth";

export default function useProducts(
  { offset, limit, productdisplayid, productid, categoryid } = {
    offset: 0,
    limit: 100000,
  }
) {
  const params = useParams();
  const { user } = useAuth();
  const orgid = user?.orgid;
  const queryClient = useQueryClient();
  const { eventid } = params;
  const { setAlert } = useGlobalAlert();

  const productsQuery = useQuery({
    queryKey: ["products", orgid, eventid],
    queryFn: () =>
      post(`products`, {
        token: user?.token,
        orgid: orgid,
        eventid: eventid,
        offset,
        limit,
      }),
    enabled: !!eventid && !!user?.token,
  });

  const products = useMemo(
    () =>
      productsQuery.data?.map((product) => {
        const accepted = (product.accepted_currencies || []).map((currencyid) =>
          parseInt(currencyid)
        );
        const iscomposed = !!product.iscomposed && product.iscomposed !== "0";
        const ispack = !!product.ispack && product.ispack !== "0";
        const isrefundable =
          !!product.isrefundable && product.isrefundable !== "0";
        return {
          ...product,
          currencies: accepted,
          iscomposed,
          ispack,
          isrefundable,
        };
      }),
    [productsQuery.data]
  );

  const categoriesQuery = useQuery({
    queryKey: ["categories", orgid, eventid],
    queryFn: () =>
      post(`categories`, {
        token: user?.token,
        orgid: orgid,
        eventid: eventid,
      }),
    enabled: !!eventid && !!user?.token,
  });

  const displaysQuery = useQuery({
    queryKey: ["productdisplays", orgid, eventid],
    queryFn: () =>
      post(`productdisplays`, {
        token: user?.token,
        orgid: orgid,
        eventid: eventid,
      }),
    enabled: !!eventid && !!user?.token,
  });

  const productsindisplayQuery = useQuery({
    queryKey: ["productsindisplay", orgid, eventid, productdisplayid],
    queryFn: () =>
      post(`productsindisplay`, {
        token: user?.token,
        orgid: orgid,
        eventid: eventid,
        productdisplayid: productdisplayid,
      }),
    enabled: !!eventid && !!user?.token,
  });

  const productsindisplay = useMemo(
    () =>
      productsindisplayQuery.data?.map((prod) => {
        const found = products?.find(
          (product) => parseInt(product.productid) === parseInt(prod.productid)
        );
        return { ...prod, ...found };
      }),
    [productsindisplayQuery.data, products]
  );

  const allproductdisplays = useMemo(
    () =>
      displaysQuery.data?.map((entry) => {
        const product = products?.find(
          (product) => parseInt(product.productid) === parseInt(entry.productid)
        );
        return { ...entry, ...product };
      }) || [],
    [products, displaysQuery.data]
  );

  const productdisplays = useMemo(
    () =>
      allproductdisplays?.filter(
        (entry) => productdisplayid === entry.productdisplayid
      ),
    [allproductdisplays, productdisplayid]
  );

  const productsincategory = useMemo(
    () =>
      products?.filter(
        (product) =>
          !categoryid ||
          categoryid === "" ||
          parseInt(categoryid) === parseInt(product.categoryid)
      ),
    [products, categoryid]
  );

  const createProductMutation = useMutation({
    mutationFn: (body) =>
      post(`createproduct`, {
        ...body,
        token: user?.token,
        accepted_currencies: `[${body?.accepted_currencies || ""}]`,
        image: typeof body?.image,
        vprice: body?.vprice ? body.vprice : 0,
        isrefundable: !!body?.isrefundable,
        ispack: !!body?.ispack,
        iscomposed: !!body?.iscomposed,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["products", orgid, eventid]);
      setAlert({ variant: "success", message: "Producto creado" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["products", orgid, eventid]);
    },
  });

  const editProductMutation = useMutation({
    mutationFn: (body) =>
      post(`editproduct`, {
        ...body,
        token: user?.token,
        accepted_currencies: `[${body?.accepted_currencies || ""}]`,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["products", orgid, eventid]);
      setAlert({ variant: "success", message: "Producto actualizado" });
    },
    onError: (error, ...args) => {
      console.log("editProductMutation error", error, args);
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["products", orgid, eventid]);
    },
  });

  const deleteProductMutation = useMutation({
    mutationFn: (body) =>
      post(`deleteproduct`, { ...body, token: user?.token }),
    onMutate: (body) => {
      if (
        window.confirm("¿Estás seguro de que deseas eliminar este producto?")
      ) {
        return body;
      }
      return Promise.reject();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["products", orgid, eventid]);
      setAlert({ variant: "success", message: "Producto eliminado" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["products", orgid, eventid]);
    },
  });

  const createCategoryMutation = useMutation({
    mutationFn: (body) =>
      post(`createcategory`, { ...body, token: user?.token }),
    onSuccess: () => {
      queryClient.invalidateQueries(["categories", eventid]);
      setAlert({ variant: "success", message: "Categoría creada" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["categories", eventid]);
    },
  });

  const deleteCategoryMutation = useMutation({
    mutationFn: (body) =>
      post(`deletecategory`, { ...body, token: user?.token }),
    onMutate: (body) => {
      if (
        window.prompt(
          "¿Estás seguro de que deseas eliminar esta categoría?\nTodos los productos de esta categoría serán eliminados.\n\nEscribe el nombre de la categoría para confirmar la eliminación."
        ) === body.name
      ) {
        return body;
      }
      return Promise.reject();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["categories", orgid]);
      setAlert({ variant: "success", message: "Categoría eliminada" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["categories", orgid]);
    },
  });

  const createDisplayMutation = useMutation({
    mutationFn: (body) =>
      post(`createproductdisplay`, { ...body, token: user?.token }),
    onSuccess: () => {
      queryClient.invalidateQueries(["displays", orgid]);
      setAlert({ variant: "success", message: "Display creado" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["displays", orgid]);
    },
  });

  const editDisplayMutation = useMutation({
    mutationFn: (body) =>
      post(`editproductdisplay`, { ...body, token: user?.token }),
    onSuccess: () => {
      queryClient.invalidateQueries(["displays", orgid]);
      setAlert({ variant: "success", message: "Display actualizado" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["displays", orgid]);
    },
  });

  const deleteDisplayMutation = useMutation({
    mutationFn: (body) =>
      post(`deleteproductdisplay`, { ...body, token: user?.token }),
    onSuccess: () => {
      queryClient.invalidateQueries(["displays", orgid]);
      setAlert({ variant: "success", message: "Display actualizado" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["displays", orgid]);
    },
  });

  const product = useMemo(
    () =>
      products?.find(
        (product) => parseInt(product.productid) === parseInt(productid)
      ),
    [products, productid]
  );

  const addProductToDisplayMutation = useMutation({
    mutationFn: (body) =>
      post(`addproducttodisplay`, { ...body, token: user?.token }),
    onSuccess: () => {
      queryClient.invalidateQueries(["productdisplays", orgid]);
      setAlert({ variant: "success", message: "Producto agregado a display" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["productdisplays", orgid]);
    },
  });

  const editProductInDisplayMutation = useMutation({
    mutationFn: (body) =>
      post(`editproductindisplay`, { ...body, token: user?.token }),
    onSuccess: () => {
      queryClient.invalidateQueries(["productdisplays", orgid]);
      setAlert({ variant: "success", message: "Producto editado en display" });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["productdisplays", orgid]);
    },
  });

  const removeProductFromDisplayMutation = useMutation({
    mutationFn: (body) =>
      post(`removeproductindisplay`, { ...body, token: user?.token }),
    onSuccess: () => {
      queryClient.invalidateQueries(["productdisplays", orgid]);
      setAlert({
        variant: "success",
        message: "Producto eliminado de display",
      });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.code
          ? error.code
          : "Error desconocido",
      });
      queryClient.invalidateQueries(["productdisplays", orgid]);
    },
  });

  return {
    productsQuery,
    products: products,
    productsincategory,
    createProductMutation,
    createProduct: createProductMutation.mutate,
    editProductMutation,
    editProduct: editProductMutation.mutate,
    deleteProductMutation,
    deleteProduct: deleteProductMutation.mutate,
    categoriesQuery,
    categories: categoriesQuery.data,
    createCategoryMutation,
    createCategory: createCategoryMutation.mutate,
    deleteCategoryMutation,
    deleteCategory: deleteCategoryMutation.mutate,
    displaysQuery,
    displays: displaysQuery.data,
    createDisplayMutation,
    createDisplay: createDisplayMutation.mutate,
    editDisplayMutation,
    editDisplay: editDisplayMutation.mutate,
    deleteDisplayMutation,
    deleteDisplay: deleteDisplayMutation.mutate,
    productsindisplayQuery,
    productsindisplay,
    allproductdisplays,
    productdisplays,
    product,
    addProductToDisplayMutation,
    addProductToDisplay: addProductToDisplayMutation.mutate,
    editProductInDisplayMutation,
    editProductInDisplay: editProductInDisplayMutation.mutate,
    removeProductFromDisplayMutation,
    removeProductFromDisplay: removeProductFromDisplayMutation.mutate,
  };
}
