import { Button, Col, Form, Row } from "react-bootstrap";
import useLang from "../lang/useLang";
import styles from "./TagSearch.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useMemo, useRef, useState } from "react";
import FastTable, { TABLE_TYPES } from "./FastTable/FastTable";
import { TX_TYPES } from "../constants";

export default function TagSearchPublic({
  tag,
  setTag,
  eventid,
  start,
  end,
  currencies = {},
  paymentMethods = [],
  locations = [],
  users = [],
  devices = [],
  transactions = [],
}) {
  const { trans } = useLang();
  const data = useMemo(() => {
    return transactions.filter((tx) => tx.tag === tag);
  }, [tag, transactions]);

  const user = useMemo(
    () => users.filter((u) => u.tag === tag),
    [users, tag]
  );

  const userCurrencies = useMemo(() => {
    if (user?.length > 0) {
      const _user = user[0];
      return Object.values(currencies).map((currency) => {
        const currIdKey = Object.keys(_user).find((key) => {
          console.log(key, currency.currencyid, _user[key]);
          return (
            /^currencyid\d+$/.test(key) && _user[key] === currency.currencyid
          );
        });
        if (currIdKey) {
          const currencyIndex = parseInt(currIdKey.replace("currencyid", ""));
          return {
            ...currency,
            balance: _user[`balance${currIdKey.replace("currencyid", "")}`],
            date: _user[`date${currencyIndex}`],
            userid: _user[`userid${currencyIndex}`],
            deviceid: _user[`deviceid${currencyIndex}`],
          };
        }
        return { ...currency, balance: 0 };
      });
    }
    return [];
  }, [user, currencies]);

  const handleOnSubmit = (e) => {
    const form = e.target;
    const formData = new FormData(form);
    const searchQuery = formData.get("search");
    // extract parameter s
    setTag(searchQuery);
    e.preventDefault();
  };

  return (
    <>
      <Row>
        <Col
          className="mb-4"
          md={{ span: 6, offset: 3 }}
          xl={{ span: 4, offset: 4 }}
        >
          <Form className={styles.TagSearchForm} onSubmit={handleOnSubmit}>
            <Form.Label>{trans("nav.search")}</Form.Label>
            <Form.Control
              type="text"
              name="search"
              placeholder={trans("nav.search")}
            />
            <Button variant="primary" type="submit">
              <FontAwesomeIcon icon={faSearch} />
            </Button>
          </Form>
        </Col>
      </Row>
      {tag !== "" && (
        <>
          <Row>
            <Col>
              <h2 className="mb-4">
                Tag "<strong>{tag}</strong>"
              </h2>
              <Row>
                <Col>
                  <FastTable
                    title={"Saldos"}
                    data={userCurrencies}
                    columns={[
                      {
                        key: "currencyid",
                        label: "ID",
                        type: TABLE_TYPES.number,
                        style: { width: 40 },
                      },
                      {
                        key: "name",
                        label: "Nombre",
                        type: TABLE_TYPES.string,
                      },
                      {
                        key: "balance",
                        label: trans("reports.balance"),
                        type: TABLE_TYPES.currency,
                        prefix: "$ ",
                      },
                      {
                        key: "date",
                        label: trans("reports.last_update"),
                        type: TABLE_TYPES.dateUnix,
                      },
                      {
                        key: "deviceid",
                        label: "deviceid",
                        type: TABLE_TYPES.options,
                        options:
                          devices?.map((d) => ({
                            value: d.deviceid,
                            label: `${d.name} (${d.deviceshorttag})`,
                          })) || [],
                      },
                    ]}
                  />
                </Col>
              </Row>
              <FastTable
                title="Transacciones"
                data={data}
                columns={[
                  { key: "txid", type: TABLE_TYPES.string, label: "txid" },
                  {
                    key: "offtxid",
                    type: TABLE_TYPES.string,
                    label: "offtxid",
                  },
                  {
                    key: "date",
                    type: TABLE_TYPES.dateUnixDate,
                    label: "Fecha",
                  },
                  {
                    key: "date",
                    type: TABLE_TYPES.dateUnixTime,
                    label: "Hora",
                  },
                  {
                    key: "type",
                    type: TABLE_TYPES.options,
                    options: Object.values(TX_TYPES),
                    label: "type",
                  },
                  {
                    key: "currencyid",
                    type: TABLE_TYPES.options,
                    options: Object.values(currencies).map((c) => ({
                      value: c.currencyid,
                      label: c.name,
                    })),
                    label: "Moneda",
                  },
                  {
                    key: "paymentmethodid",
                    type: TABLE_TYPES.options,
                    options: (Array.isArray(paymentMethods) ? paymentMethods : []).map((pm) => ({
                      value: pm.paymentmethodid,
                      label: pm.name,
                    })),
                    label: "Método de pago",
                  },
                  {
                    key: "amount",
                    type: TABLE_TYPES.currency,
                    prefix: "$ ",
                    label: "Monto",
                  },
                  {
                    key: "products",
                    label: "Items",
                    type: TABLE_TYPES.string,
                    render: (row) =>
                      Array.isArray(row?.products)
                        ? row?.products
                          ?.map(
                            (product) =>
                              `${product.name
                                ? product.name
                                : `#${product.productid}`
                              } x${product.qty}`
                          )
                          .join(", ")
                        : "",
                  },
                  {
                    key: "locationid",
                    type: TABLE_TYPES.options,
                    options:
                      (Array.isArray(locations) ? locations : []).map((loc) => ({
                        value: loc.locationid,
                        label: loc.name,
                      })) || [],
                    label: "Ubicación",
                  },
                  // { key: "status", type: TABLE_TYPES.string, label: "status" },
                  {
                    key: "deviceName",
                    type: TABLE_TYPES.string,
                    label: "Terminal",
                  },
                  {
                    key: "userName",
                    type: TABLE_TYPES.string,
                    label: "Operario",
                  },
                ]}
                showFilters={false}
                showGroups={false}
              />
            </Col>
          </Row>
          <hr />
        </>
      )}
    </>
  );
}
