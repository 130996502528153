import { useParams } from "react-router-dom";
import { usePage } from "../../hooks/usePage";
import useLang from "../../lang/useLang";
import { usePublicSummary } from "../../hooks/usePublicSummary";
import Viewer from "../../components/Viewer";
import useSearchParamState from "../../hooks/useSearchParamState";

export default function Page() {
  const { trans } = useLang();
  usePage({ title: trans("nav.viewer") });
  const params = useParams();
  const rtoken = params.rtoken;

  const [dateStart] = useSearchParamState("st");
  const [dateEnd] = useSearchParamState("ed");

  const {
    totalTopUpsAmount,
    totalTopUpsPayments,
    totalSales,
    totalSalesAmount,
    noPaymentInfo,
    noPaymentInfoAmount,
    totalInits,
    cashiers,
    salespeople,
    locations,
    products,
    currencies,
    refetch,
    validData,
  } = usePublicSummary({
    start: dateStart,
    end: dateEnd,
    limit: 1000000000,
    offset: 0,
    rtoken,
  });

  return (
    <Viewer
      totalTopUpsAmount={totalTopUpsAmount}
      totalTopUpsPayments={totalTopUpsPayments}
      totalSales={totalSales}
      totalSalesAmount={totalSalesAmount}
      totalInits={totalInits}
      cashiers={cashiers}
      salespeople={salespeople}
      locations={locations}
      products={products}
      currencies={currencies}
      refetch={refetch}
      noPaymentInfo={noPaymentInfo}
      noPaymentInfoAmount={noPaymentInfoAmount}
      filterZeroes={validData?.length > 0}
    />
  );
}
