import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import { imageUploader, post } from "../../utils/Api";
import styles from "./FastForm.module.scss";
import { Alert, Button, Spinner } from "react-bootstrap";
import AutoComplete from "../AutoComplete";
import ImageField from "./ImageField";
import { useGlobalAlert } from "../../hooks/useGlobalAlert";
import MultiSelectWrapper from "./MultiSelectWrapper";
import useLang from "../../lang/useLang";

export default function FastForm({
  fields,
  endpoint,
  mutation,
  parseData = (data) => data,
  postParseData = (data) => data,
  validate = () => true,
  sendText = "Enviar",
  className = "",
  clearOnSuccess = false,
  append = null,
  autoComplete = null,
}) {
  const { trans, lang } = useLang();
  const [errors, setErrors] = useState({});
  const { setAlert } = useGlobalAlert();

  const fieldRefs = useRef({});

  const sendButtonText = useMemo(() => {
    if (sendText === "Enviar" && lang) {
      return trans("fastform.send");
    }
    return sendText;
  }, [sendText, trans, lang]);

  const endpointMutation = useMutation({
    mutationFn: (data) => {
      return post(endpoint, {
        ...data,
      });
    },
    onError: (error) => {
      setAlert({
        variant: "danger",
        message: error?.message
          ? error.message
          : error?.msg
          ? error.msg
          : error?.code
          ? error.code
          : "Error desconocido",
      });
    },
    onSuccess: () => {
      setAlert({ variant: "success", message: "Operación exitosa" });
    },
  });

  const effectiveMutation = useMemo(
    () => mutation || endpointMutation,
    [mutation, endpointMutation]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const formData = new FormData(e.target);
      const data = {};
      for (let [key, value] of formData.entries()) {
        data[key] = value;
      }
      console.log("data", data);
      const parsedData = parseData(data);
      console.log("parsedData", parsedData);
      const valid = validate(parsedData);
      if (valid !== true) {
        if (typeof valid === "object") {
          setErrors(valid);
        }
        effectiveMutation.reset();
        return;
      }
      const postParsedData = postParseData(parsedData);
      setErrors({});
      effectiveMutation.mutate(postParsedData);
    },
    [effectiveMutation, validate, parseData, postParseData]
  );

  useEffect(() => {
    if (effectiveMutation.isSuccess && clearOnSuccess) {
      for (let key in fieldRefs.current) {
        console.log("clearing", key);
        console.log(fieldRefs.current[key]);
        if (fieldRefs.current[key]) {
          const field = fields.find((f) => f.name === key);
          if (field?.defaultValue) {
            fieldRefs.current[key].value = field.defaultValue;
          } else if (typeof field?.defaultChecked !== "undefined") {
            fieldRefs.current[key].checked = !!field.defaultChecked;
          } else {
            fieldRefs.current[key].value = "";
          }
        }
      }
    }
  }, [
    fields,
    effectiveMutation.isSuccess,
    effectiveMutation.status,
    clearOnSuccess,
  ]);

  // useEffect(() => {
  //   console.log("rerender FastForm with fields");
  // }, [fields]);

  return (
    <Form
      onSubmit={handleSubmit}
      className={[className, styles.FastForm].join(" ")}
      autoComplete={autoComplete}
    >
      <div>
        {fields.map((field) => {
          return (
            <Form.Group
              key={field.name}
              className={[
                styles.FormGroup,
                field?.inline && styles.FieldInline,
                field?.type === "hidden" ? "d-none" : "",
                styles[`Field${field.type}`],
                field?.className || "",
              ].join(" ")}
              style={field?.style || {}}
              controlId={field.name}
            >
              {field?.type !== "hidden" && (
                <Form.Label>
                  {field.label}
                  {field?.attributes?.required ? "*" : ""}
                </Form.Label>
              )}
              <div className="input-group">
                {field.prefix && (
                  <span className="input-group-text">{field.prefix}</span>
                )}
                {field.type === "autocomplete" ? (
                  <AutoComplete
                    {...field.autocomplete}
                    name={field.name}
                    className={styles.AutoComplete}
                  />
                ) : field.type === "multi-select" ? (
                  <MultiSelectWrapper
                    {...field?.attributes}
                    name={field.name}
                    defaultValue={field?.defaultValue}
                    options={field.options}
                  />
                ) : field.type === "select" ? (
                  <Form.Select
                    {...field?.attributes}
                    name={field.name}
                    defaultValue={field?.defaultValue}
                  >
                    {field.options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                ) : field.type === "checkbox" ? (
                  <Form.Check
                    type="checkbox"
                    name={field.name}
                    defaultChecked={field.defaultValue}
                  />
                ) : field.type === "image" ? (
                  <>
                    <ImageField
                      defaultValue={field?.defaultValue}
                      name={field.name}
                      uploader={imageUploader}
                      {...field?.attributes}
                    />
                  </>
                ) : field.type === "toggle" ? (
                  <Toggle {...field} />
                ) : (
                  <Form.Control
                    type={field.type}
                    placeholder={field.placeholder}
                    {...field?.attributes}
                    defaultValue={field?.defaultValue}
                    name={field.name}
                    ref={(ref) => (fieldRefs.current[field.name] = ref)}
                  />
                )}
                {field.suffix && (
                  <span className="input-group-text">{field.suffix}</span>
                )}
              </div>
              {errors[field.name] && (
                <Form.Text className="text-danger">
                  {errors[field.name]}
                </Form.Text>
              )}
            </Form.Group>
          );
        })}
      </div>
      <div className={styles.FormButtons}>
        {effectiveMutation.isSuccess && (
          <Alert variant="success" className="p-1 text-center">
            {effectiveMutation?.data?.msg
              ? typeof effectiveMutation.data.msg === "string"
                ? effectiveMutation.data.msg
                : "Operación exitosa " +
                  JSON.stringify(effectiveMutation.data.msg)
              : effectiveMutation?.data?.message
              ? effectiveMutation.data.message
              : "Operación exitosa"}
          </Alert>
        )}
        {effectiveMutation.isError && (
          <Alert variant="danger" className="p-1 text-center">
            {effectiveMutation?.error?.message
              ? effectiveMutation.error.message
              : effectiveMutation?.error?.msg
              ? effectiveMutation.error.msg
              : "Hubo un error"}
          </Alert>
        )}
        <Button
          primary={"primary"}
          className="w-100"
          type="submit"
          disabled={endpointMutation?.isLoading}
        >
          {endpointMutation?.isLoading ? (
            <Spinner animation="border" />
          ) : (
            sendButtonText
          )}
        </Button>
        {append}
      </div>
    </Form>
  );
}

function ControlledCheckbox({ name, defaultValue }) {
  const [checked, setChecked] = useState(defaultValue);

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <pre>
        {defaultValue ? "checked" : "not checked"}
        {", "}
        {checked ? "checked" : "not checked"}
      </pre>
      <Form.Check
        type="checkbox"
        name={name}
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
    </div>
  );
}

function Toggle({ options, name, defaultValue }) {
  const [value, setValue] = useState(defaultValue);
  return (
    <div style={{ display: "flex", width: "100%" }}>
      {options.map((option, index) => (
        <label
          key={index}
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            backgroundColor:
              value.toString() === option.value.toString()
                ? option?.color
                  ? option.color
                  : "var(--bs-primary)"
                : "var(--bs-secondary)",
            color:
              value.toString() === option.value.toString()
                ? "var(--bs-white)"
                : option?.color
                ? option.color
                : "var(--bs-primary)",
            padding: 10,
            cursor: "pointer",
          }}
        >
          <input
            type="radio"
            key={index}
            name={name}
            value={option.value}
            checked={value.toString() === option.value.toString()}
            onChange={(e) => setValue(e.target.value)}
            style={{
              opacity: 0,
              position: "absolute",
              zIndex: -1,
              width: 0,
              height: 0,
            }}
          />
          {option.label}
        </label>
      ))}
    </div>
  );
}
