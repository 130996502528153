import { useState, Fragment, useMemo, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { usePage } from "../hooks/usePage";
import useLang from "../lang/useLang";
import ViewerCard, { VIEWER_CARD_SIZES } from "./ViewerCard";
import FastTable, { TABLE_TYPES } from "./FastTable/FastTable";
import { CHART_COLORS } from "../constants";
import PillToggler from "./PillToggler";
import styles from "./Viewer.module.scss";

export default function Viewer({
  totalTopUpsAmount,
  totalTopUpsPayments,
  totalSales,
  totalSalesAmount,
  totalInits,
  cashiers,
  salespeople,
  locations,
  products,
  currencies,
  filterZeroes = false,
}) {
  const { trans } = useLang();
  usePage({ title: trans("nav.viewer") });

  const currenciesFiltered = useMemo(
    () =>
      Object.keys(currencies).reduce((acc, currencyid) => {
        const currency = currencies[currencyid];
        if (currency.topUpsAmount || currency.salesAmount) {
          acc[currencyid] = currency;
        }
        return acc;
      }, {}),
    [currencies]
  );
  const [selectedCurrenciesForTotal, setSelectedCurrenciesForTotal] = useState(
    currenciesFiltered
      ? Object.values(currenciesFiltered)
        .filter(
          (currency) => currency?.name.toLowerCase().indexOf("cortes") === -1
        )
        .map((currency) => currency.currencyid)
      : []
  );

  useEffect(() => {
    if (
      selectedCurrenciesForTotal.length === 0 &&
      currenciesFiltered &&
      Object.keys(currenciesFiltered).length > 0
    ) {
      setSelectedCurrenciesForTotal(
        Object.values(currenciesFiltered)
          .filter(
            (currency) => currency?.name.toLowerCase().indexOf("cortes") === -1
          )
          .map((currency) => currency.currencyid)
      );
    }
  }, [currenciesFiltered, selectedCurrenciesForTotal]);

  const filteredTotalTopUpsAmount = useMemo(() => {
    return Object.values(currenciesFiltered).reduce(
      (acc, payment) =>
        (selectedCurrenciesForTotal.includes(payment.currencyid)
          ? payment.topUpsAmount + payment.refundsAmount
          : 0) + acc,
      0
    );
  }, [currenciesFiltered, selectedCurrenciesForTotal]);

  const residue = useMemo(() => {
    return (
      Object.values(currenciesFiltered).reduce((result, curr) => {
        if (selectedCurrenciesForTotal.includes(curr.currencyid)) {
          return (
            result + curr.topUpsAmount + curr.refundsAmount - curr.salesAmount
          );
        }
        return result;
      }, 0) || 0
    );
  }, [selectedCurrenciesForTotal, currenciesFiltered]);

  return (
    <Fragment>
      <Row>
        {(filteredTotalTopUpsAmount || !filterZeroes) && (
          <Col sm={12} md={6} xl={3} style={{ flexGrow: 1 }}>
            <Row>
              {
                <ViewerCard
                  title="Total Recargas"
                  prefix="$ "
                  value={filteredTotalTopUpsAmount}
                  sm={12}
                  size={VIEWER_CARD_SIZES.MEDIUM}
                >
                  {totalTopUpsPayments && (
                    <CurrencyToggler
                      currencies={currenciesFiltered}
                      selected={selectedCurrenciesForTotal}
                      setSelected={setSelectedCurrenciesForTotal}
                    />
                  )}
                </ViewerCard>
              }
              {totalTopUpsPayments &&
                Object.values(totalTopUpsPayments).map(
                  (payment) =>
                    (payment.amount || !filterZeroes) && (
                      <ViewerCard
                        key={payment.paymentmethodid}
                        title={`${!payment?.paymentmethodid ? "Recargas " : ""}${payment.name
                          }`}
                        prefix="$ "
                        value={payment.amount}
                        xs={12}
                        lg={6}
                        className="flex-grow-1"
                        size={VIEWER_CARD_SIZES.SMALL}
                      >
                        <div className={styles.TableContainer}>
                          <div>
                            {Object.values(payment?.currencies || {})?.map(
                              (currency) => (
                                <div key={currency.currencyid} >
                                  <div
                                    style={{
                                      textAlign: "left",
                                    }}
                                  >
                                    {currency.name} x {currency.count.toLocaleString()}
                                  </div>
                                  <div
                                    style={{
                                      textAlign: "right",
                                    }}
                                  >
                                    $ {currency.amount.toLocaleString()}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </ViewerCard>
                    )
                )}
            </Row>
          </Col>
        )}
        {(totalSales || !filterZeroes) && (
          <ViewerCard
            title="Número Total de Ventas"
            value={totalSales}
            sm={6}
            xl={3}
            size={VIEWER_CARD_SIZES.LARGE}
            style={{ flexGrow: 1 }}
          >
            {currenciesFiltered && (
              <div className={styles.TableContainer}>
                <div>
                  {Object.values(currenciesFiltered).map((currency) => (
                    <div key={currency.currencyid}>
                      <div style={{ textAlign: "left" }}>{currency.name} x {currency.sales}</div>
                      <div style={{ textAlign: "right" }}>
                        $ {currency.salesAmount.toLocaleString()}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </ViewerCard>
        )}
        {((residue > 0 && totalSales !== 0) || !filterZeroes) && (
          <ViewerCard
            title="No gastado"
            prefix="$ "
            value={residue}
            sm={6}
            xl={3}
            size={VIEWER_CARD_SIZES.LARGE}
            style={{ flexGrow: 1 }}
          >
            <CurrencyToggler
              currencies={currenciesFiltered}
              selected={selectedCurrenciesForTotal}
              setSelected={setSelectedCurrenciesForTotal}
            />
            {currenciesFiltered && (
              <div className={styles.TableContainer}>
                <div>
                  {Object.values(currenciesFiltered).map((currency) => (
                    <div key={currency.currencyid}>
                      <div style={{ textAlign: "left" }}>{currency.name}</div>
                      <div style={{ textAlign: "right" }}>
                        ${" "}
                        {(
                          currency.topUpsAmount +
                          currency.refundsAmount -
                          currency.salesAmount
                        ).toLocaleString()}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </ViewerCard>
        )}
        {(totalInits || !filterZeroes) && (
          <ViewerCard
            title="Activaciones"
            value={totalInits}
            sm={6}
            xl={3}
            size={VIEWER_CARD_SIZES.LARGE}
            style={{ flexGrow: 1 }}
          />
        )}
        {Object.values(currencies).map((currency) => {
          if (currency.topUpsAmount || currency.salesAmount) {
            const loads =
              currency.topUpsAmount +
              currency.refundsAmount +
              currency.wipesAmount;
            return (
              <Fragment key={currency.currencyid}>
                {(loads || !filterZeroes) && (
                  <ViewerCard
                    title={`Total carga en ${currency.name}`}
                    sm={6}
                    xl={2}
                    prefix="$ "
                    value={loads}
                    size={VIEWER_CARD_SIZES.LARGE}
                    style={{ flexGrow: 1 }}
                  >
                    <div style={{ fontSize: 12 }} className={styles.TableContainer}>
                      <div>
                        <div>
                          <div style={{ textAlign: "left" }}>Cargas x {currency?.topUps}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            $ {currency?.topUpsAmount?.toLocaleString()}
                          </div>
                        </div>
                        <div>
                          <div style={{ textAlign: "left" }}>Devoluciones x {currency?.refunds}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            $ {currency?.refundsAmount?.toLocaleString()}
                          </div>
                        </div>
                        <div>
                          <div style={{ textAlign: "left" }}>Limpieza x {currency?.wipes}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            $ {currency?.wipesAmount?.toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </ViewerCard>
                )}
                {(currency.salesAmount || !filterZeroes) && (
                  <ViewerCard
                    title={`Total venta en ${currency.name}`}
                    sm={6}
                    xl={2}
                    prefix="$ "
                    value={currency.salesAmount}
                    size={VIEWER_CARD_SIZES.LARGE}
                    style={{ flexGrow: 1 }}
                  >
                    <div style={{ fontSize: 12 }} className={styles.TableContainer}>
                      <div>
                        <div>
                          <div>-</div>
                        </div>
                        <div>
                          <div>-</div>
                        </div>
                        <div>
                          <div>-</div>
                        </div>
                      </div>
                    </div>
                  </ViewerCard>
                )}
              </Fragment>
            );
          } else {
            return null;
          }
        })}
      </Row>
      <Row>
        {(Object.keys(cashiers).length || !filterZeroes) && (
          <ViewerCard
            title="Recarga x cajero"
            sm={12}
            xl={6}
            style={{ flexGrow: 1 }}
          >
            <FastTable
              showFilters={false}
              showGroups={false}
              showExcel={false}
              showPagination={false}
              data={Object.values(cashiers)}
              pieChart={Object.keys(cashiers).length > 1 && {
                data: Object.values(cashiers).sort((a, b) => b.amount - a.amount),
                legend: { dataKey: "name", label: "Nombre" },
                dataKey: "amount",
                label: "Monto",
                prefix: "$",
                palette: CHART_COLORS,
              }}
              columns={[
                { label: "ID", key: "userid" },
                { label: "Nombre", key: "name", type: TABLE_TYPES.string },
                ...(Object.values(totalTopUpsPayments).map((payment) => ({
                  label: payment.name,
                  key: `pm-${payment.paymentmethodid}`,
                  type: TABLE_TYPES.currency,
                  prefix: "$",
                  render: (row) => row.payments[payment.paymentmethodid]?.amount || 0,
                }))
                ),
                {
                  label: "Total",
                  key: "amount",
                  type: TABLE_TYPES.currency,
                  prefix: "$",
                },
              ]}
            />
          </ViewerCard>
        )}
        {(Object.keys(salespeople).length || !filterZeroes) && (
          <ViewerCard
            title="Venta x bartender"
            sm={12}
            xl={6}
            style={{ flexGrow: 1 }}
          >
            <FastTable
              showFilters={false}
              showGroups={false}
              showExcel={false}
              showPagination={false}
              data={Object.values(salespeople)}
              pieChart={Object.keys(salespeople).length > 1 && {
                data: Object.values(salespeople).sort(
                  (a, b) => b.amount - a.amount
                ),
                legend: { dataKey: "name", label: "Nombre" },
                dataKey: "amount",
                label: "Monto",
                prefix: "$",
                palette: CHART_COLORS,
              }}
              columns={[
                { label: "ID", key: "userid" },
                { label: "Nombre", key: "name", type: TABLE_TYPES.string },
                {
                  label: "Total",
                  key: "amount",
                  type: TABLE_TYPES.currency,
                  prefix: "$",
                },
              ]}
            />
          </ViewerCard>
        )}
        {(Object.keys(locations).length || !filterZeroes) && (
          <ViewerCard
            title="Venta x punto de venta"
            sm={12}
            xl={6}
            style={{ flexGrow: 1 }}
          >
            <FastTable
              showFilters={false}
              showGroups={false}
              showExcel={false}
              showPagination={false}
              data={Object.values(locations)}
              pieChart={Object.keys(locations).length > 1 && {
                data: Object.values(locations).sort(
                  (a, b) => b.amount - a.amount
                ),
                legend: { dataKey: "name", label: "Nombre" },
                dataKey: "amount",
                label: "Monto",
                prefix: "$",
                palette: CHART_COLORS,
              }}
              columns={[
                { label: "ID", key: "locationid" },
                { label: "Nombre", key: "name", type: TABLE_TYPES.string },
                {
                  label: "Total",
                  key: "amount",
                  type: TABLE_TYPES.currency,
                  prefix: "$",
                },
              ]}
            />
          </ViewerCard>
        )}
        {(Object.keys(products).length || !filterZeroes) && (
          <ViewerCard title={`Venta x producto`} sm={12} xl={6}>
            <FastTable
              showFilters={false}
              showGroups={false}
              showExcel={false}
              showPagination={true}
              data={Object.values(products)}
              pieChart={Object.keys(products).length > 1 && {
                data: Object.values(products).sort((a, b) => b.amount - a.amount),
                legend: { dataKey: "name", label: "Nombre" },
                dataKey: "amount",
                label: "Monto",
                prefix: "$",
                palette: CHART_COLORS,
              }}
              columns={[
                { label: "ID", key: "productid" },
                { label: "Nombre", key: "name", type: TABLE_TYPES.string },
                {
                  label: "Precio",
                  key: "price",
                  type: TABLE_TYPES.currency,
                  prefix: "$",
                },
                {
                  label: "Vent.",
                  key: "qty",
                  type: TABLE_TYPES.number,
                  // prefix: "x ",
                  totals: true,
                },
                {
                  label: "Devol.",
                  key: "qty_return",
                  type: TABLE_TYPES.number,
                  prefix: "-",
                  totals: true,
                },
                {
                  label: "Cant.",
                  key: "qty_net",
                  type: TABLE_TYPES.number,
                  style: {whiteSpace: "nowrap"},
                  totals: true,
                },
                {
                  label: "Total",
                  key: "amount",
                  type: TABLE_TYPES.currency,
                  prefix: "$",
                  totals: true,
                },
              ]}
            />
          </ViewerCard>
        )}
      </Row>
    </Fragment>
  );
}

function CurrencyToggler({ currencies, selected, setSelected }) {
  return (
    <PillToggler
      multiple={true}
      options={Object.values(currencies).map((payment) => ({
        value: payment.currencyid,
        label: payment.name,
      }))}
      selected={selected?.length > 0 ? selected : Object.keys(currencies)}
      setSelected={(val) => setSelected((prev) => (prev === val ? null : val))}
      style={{ justifyContent: "center", gap: 5 }}
      className={styles.PillToggler}
      pillClassName={styles.PillItem}
      pillActiveClassName={styles.PillActive}
    />
  );
}
