import {
  matchRoutes,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Events/Home";
import Event from "./pages/Events/Event";
import Users from "./pages/Users/Users";
import { useAuth } from "./hooks/useAuth";
import { useEffect, useMemo } from "react";
import Layout from "./components/Layout";
import CreateUser from "./pages/Users/CreateUser";
import Settings from "./pages/Settings/Settings";
import Groups from "./pages/Users/Groups";
import CreateEvent from "./pages/Events/CreateEvent";
import UserRights from "./pages/Users/UserRights";
import Products from "./pages/Products/Products";
import Categories from "./pages/Products/Categories";
import CreateProduct from "./pages/Products/CreateProduct";
import EditUser from "./pages/Users/EditUser";
import SearchUser from "./pages/Users/SearchUser";
import Displays from "./pages/Products/Displays";
import SingleProduct from "./pages/Products/SingleProduct";
import Devices from "./pages/Devices/Devices";
import Locations from "./pages/Locations/Locations";
import Currencies from "./pages/Currencies/Currencies";
import Reports from "./pages/Reports/Reports";
import ReportsIndex from "./pages/Reports/ReportsIndex";
import ReportLoads from "./pages/Reports/ReportLoads";
import ReportSales from "./pages/Reports/ReportSales";
import ReportStock from "./pages/Reports/ReportStock";
import Viewer from "./pages/Reports/Viewer";
import ViewerRedirect from "./pages/ViewerRedirect";
import Transactions from "./pages/Reports/Transactions";
import PublicTransactions from "./pages/Public/PublicTransactions";
import PublicViewer from "./pages/Public/PublicViewer";
import PublicLayout from "./pages/Public/PublicLayout";

export const routes = [
  {
    path: "/",
    element: <Layout />,
    public: true,
    children: [
      { path: "login", element: <Login />, public: true },
      { path: "viewer/:rtoken", element: <ViewerRedirect />, public: true },
      {
        path: "pub/:rtoken",
        element: <PublicLayout />,
        public: true,
        children: [
          { path: "viewer", element: <PublicViewer />, public: true },
          { path: "txs", element: <PublicTransactions />, public: true },
        ]
      },
      { path: "", element: <Home />, public: false },
      {
        path: "createevent",
        element: <CreateEvent />,
        public: false,
      },
      { path: "devices", element: <Devices />, public: false },
      { path: "users", element: <Users />, public: false },
      {
        path: ":eventid",
        element: <Event />,
        public: false,
        children: [
          {
            path: "users",
            element: <Outlet />,
            public: false,
            children: [
              { path: "", element: <Users />, public: false },
              {
                path: "create",
                element: <CreateUser />,
                public: false,
              },
              {
                path: "search",
                element: <SearchUser />,
                public: false,
              },
              {
                path: "groups",
                element: <Groups />,
                public: false,
              },
              {
                path: "rights",
                element: <UserRights />,
                public: false,
              },
              {
                path: ":userid",
                element: <EditUser />,
                public: false,
              },
            ],
          },
          {
            path: "products",
            element: <Outlet />,
            public: false,
            children: [
              {
                path: "",
                element: <Products />,
                public: false,
              },
              {
                path: "create",
                element: <CreateProduct />,
                public: false,
              },
              {
                path: "categories",
                element: <Categories />,
                public: false,
              },
              {
                path: "display",
                element: <Displays />,
                public: false,
              },
              {
                path: ":productid",
                element: <SingleProduct />,
                public: false,
              },
            ],
          },
          {
            path: "currencies",
            element: <Outlet />,
            public: false,
            children: [{ path: "", element: <Currencies />, public: false }],
          },
          {
            path: "settings",
            element: <Settings />,
            public: false,
          },
          { path: "devices", element: <Devices />, public: false },
          { path: "locations", element: <Locations />, public: false },
          {
            path: "locations/:locationid",
            element: <Locations />,
            public: false,
          },
          {
            path: "reports",
            element: <Reports />,
            public: false,
            children: [
              { path: "", element: <ReportsIndex />, public: false },
              { path: "viewer", element: <Viewer /> },
              {
                path: "loads",
                element: <ReportLoads />,
              },
              {
                path: "sales",
                element: <ReportSales />,
              },
              {
                path: "txs",
                element: <Transactions />,
              },
              {
                path: "stock",
                element: <ReportStock />,
              },
            ],
          },
        ],
      },
    ],
  },
];

function flattenRoutes(routes, parentPath = "") {
  return routes
    .map((route) => {
      const fullPath = `${parentPath}/${route.path}`.replace(/\/+/g, "/");
      if (route?.children) {
        return flattenRoutes(route.children, fullPath);
      }
      return { ...route, path: fullPath };
    })
    .flat();
}

export const publicRoutes = flattenRoutes(routes).filter(
  (route) => route.public
);

console.log("publicRoutes", publicRoutes);

export const privateRoutes = routes.filter((route) => !route.public);
// .map((route) => route.path);

export function useRouteMiddleWare() {
  const location = useLocation();
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const { user, ready } = useAuth();

  const isPublicPage = useMemo(() => {
    const match = matchRoutes(publicRoutes, location)?.length > 0;
    console.log("publicRoutes match", publicRoutes, location, match);
    return match;
  }, [location]);
  const isLoginPage = useMemo(() => pathname === "/login", [pathname]);

  const isOrgPage = useMemo(() => {
    return !!user?.orgid && !isNaN(user?.orgid);
  }, [user]);

  const isEventPage = useMemo(() => {
    return isOrgPage && !!params.eventid && !isNaN(params.eventid);
  }, [params, isOrgPage]);

  useEffect(() => {
    console.log("isPublicPage", isPublicPage);
    if (!ready) return;
    if (!user) {
      if (!isPublicPage) {
        navigate("/login");
      }
      return;
    }
    if (user) {
      if (isLoginPage) {
        navigate(`/`);
      }
    }
  }, [ready, user, isPublicPage, isLoginPage, navigate]);

  return { params, pathname, isOrgPage, isEventPage };
}
