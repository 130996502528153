import { Col, Container, Row } from "react-bootstrap";
import useLang from "../../lang/useLang";
import { useTransactions } from "../../hooks/useReports";
import { useParams } from "react-router-dom";
import { usePage } from "../../hooks/usePage";
import { useEffect, useState } from "react";
import TagSearch from "../../components/TagSearch";
import DateRange from "../../components/FastChart/DateRange";
import FastTable from "../../components/FastTable/FastTable";
import { TABLE_TYPES } from "../../components/FastTable/FastTable";
import { TX_TYPES } from "../../constants";
import useCurrencies from "../../hooks/useCurrencies";
import useLocations from "../../hooks/useLocations";
import UpdateReportButton from "../../components/UpdateReportButton";
import useEvents from "../../hooks/useEvents";

export default function Transactions() {
  const { trans } = useLang();
  usePage({ title: trans("reports.transactions") });
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const [tag, setTag] = useState("");
  const params = useParams();
  const eventid = params.eventid;
  const { event } = useEvents();
  const { currencies, paymentMethods } = useCurrencies();
  const { locations } = useLocations({ eventid });
  const { transactions, dataUpdatedAt, isLoading, isRefetching, refetch } =
    useTransactions({
      eventid,
      limit: 1000000,
      offset: 0,
      start: dateStart,
      end: dateEnd,
    });

  useEffect(() => {
    //check url for search query if tag is set
    const urlParams = new URLSearchParams(window.location.search);
    const tagParam = urlParams.get("tag");
    if (tagParam) {
      setTag(tagParam);
    }
  }, []);

  useEffect(() => {
    // set tag search query in url if tag is set
    if (tag && tag !== "") {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("tag", tag);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${urlParams}`
      );
    }
  }, [tag]);

  useEffect(() => {
    if (!dateStart) {
      const urlParams = new URLSearchParams(window.location.search);
      const startParam = urlParams.get("st");
      if (startParam) {
        setDateStart(parseInt(startParam));
      } else if (event?.start) {
        setDateStart(parseInt(event.start));
      }
    }
  }, [event?.start, dateStart]);

  useEffect(() => {
    if (!dateEnd) {
      const urlParams = new URLSearchParams(window.location.search);
      const endParam = urlParams.get("ed");
      if (endParam) {
        setDateEnd(parseInt(endParam));
      } else if (event?.end) {
        setDateEnd(parseInt(event.end));
      }
    }
  }, [event?.end, dateEnd]);

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <h1>{trans("reports.transactions")}</h1>
        </Col>
        <Col className="justify-content-end align-items-center d-flex">
          <UpdateReportButton
            refetch={refetch}
            dataUpdatedAt={dataUpdatedAt}
            isLoading={isLoading}
            isRefetching={isRefetching}
          />
        </Col>
      </Row>
      <TagSearch
        eventid={eventid}
        start={dateStart}
        end={dateEnd}
        tag={tag}
        setTag={setTag}
      />
      <Row>
        <Col className="mb-4 text-center" lg={{ offset: 4, span: 4 }}>
          <DateRange
            type="datetime-local"
            start={dateStart}
            end={dateEnd}
            setStart={setDateStart}
            setEnd={setDateEnd}
            options={[
              { value: 60 * 60, label: trans("reports.last_1hr") },
              { value: 60 * 60 * 12, label: trans("reports.last_12hr") },
              { value: 60 * 60 * 24, label: trans("reports.last_24hr") },
              { value: 60 * 60 * 48, label: trans("reports.last_48hr") },
            ]}
          />
        </Col>
      </Row>
      {!!dateStart ? (
        <FastTable
          data={transactions}
          columns={[
            { key: "txid", label: "txid", type: TABLE_TYPES.string },
            { key: "offtxid", label: "offtxid", type: TABLE_TYPES.string },
            { key: "tag", label: "tag", type: TABLE_TYPES.number },
            {
              key: "date",
              label: "Fecha",
              filter: true,
              type: TABLE_TYPES.dateUnixDate,
            },
            {
              key: "date-time",
              sourceKey: "date",
              label: "Hora",
              filter: false,
              groupBy: false,
              type: TABLE_TYPES.dateUnixTime,
            },
            {
              key: "type",
              filter: true,
              label: "Tipo",
              type: TABLE_TYPES.options,
              options: Object.values(TX_TYPES),
            },
            {
              key: "currencyid",
              label: "Moneda",
              filter: true,
              type: TABLE_TYPES.options,
              options: currencies.map((currency) => ({
                value: currency.currencyid,
                label: currency.name,
              })),
            },
            {
              key: "amount",
              label: "amount",
              type: TABLE_TYPES.currency,
              prefix: "$ ",
              aggregateInitialValue: "0, $ 0 = $ 0",
              aggregate: (_acc, row) => {
                console.log("acc", _acc, row?.amount);
                const firstSplit = _acc.split(", $ ");
                const secondSplit = firstSplit[1].split(" = $ ");
                const splitted = [
                  parseInt(firstSplit[0]),
                  parseInt(secondSplit[0]),
                  parseInt(secondSplit[1]),
                ];
                if (parseInt(row?.amount) > 0) {
                  splitted[0] += parseInt(row.amount);
                } else {
                  splitted[1] += parseInt(row.amount);
                }
                splitted[2] = splitted[0] + splitted[1];
                return `${splitted[0]}, $ ${splitted[1]} = $ ${splitted[2]}`;
              },
            },
            {
              key: "products",
              label: "Items",
              type: TABLE_TYPES.string,
              filter: true,
              filterValue: (row) =>
                row?.products
                  ? Object.values(row?.products).map((item => item.name))
                  : "",
              filterType: "includes",
              filterRender: (row) => row?.products === "" ? "N/A" : row?.products,
              sortValue: (row) => {
                row.products.sort((a, b) => parseInt(a.productid) - parseInt(b.productid)); return row.products.map((product) => `${product.name}-x${product.qty}`).join(", ")
              },
              render: (row) =>
                Array.isArray(row?.products)
                  ? Object.values(row?.products)
                    ?.map(
                      (product) =>
                        `${product.name
                          ? product.name
                          : `#${product.productid}`
                        } x${product.qty}`
                    )
                    .join(", ")
                  : "",
            },
            {
              key: "paymentmethodid",
              filter: true,
              label: trans("reports.paymentmethod"),
              type: TABLE_TYPES.options,
              options: paymentMethods.map((paymentmethod) => ({
                value: paymentmethod.paymentmethodid,
                label: paymentmethod.name,
              })),
            },
            {
              key: "locationid",
              filter: true,
              label: "Ubicaciones",
              type: TABLE_TYPES.options,
              options: locations?.map((location) => ({
                value: location.locationid,
                label: location.name + " (" + location.locationid + ")",
              })),
            },
            {
              key: "deviceshorttag",
              label: "Terminal",
              type: TABLE_TYPES.string,
              render: (row) =>
                `${row.deviceName ? row.deviceName : "-"} (${row.deviceshorttag
                })`,
            },
            { key: "userName", label: "userName", type: TABLE_TYPES.string, filter: true },
            {
              key: "verified",
              label: "Ver",
              render: (row) => <div style={{ textAlign: "center" }}>{
                row.verified === "1" ? "✔️" : "❓"}</div>,
              type: TABLE_TYPES.string,
              filter: true,
              filterType: "includes",
              filterRender: (row) => row.verified === "1" ? "✔️" : "❓",
            }
          ]}
          cellStyle={(row) => {
            if (row.verified === "0") {
              return { color: "#ea0", backgroundColor: "#ffe" };
            }
            return "";
          }}
        />
      ) : (
        <Row>
          <Col>
            <h2 className="text-center">Seleccione un rango de fechas</h2>
          </Col>
        </Row>
      )}
    </Container>
  );
}
