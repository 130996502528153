import {
  BOOL_AND,
  BOOL_NOT_AND,
  BOOL_NOT_OR,
  BOOL_OR,
  BOOL_GT,
  BOOL_ST,
} from "./FastTable/FastFilter";
import { TABLE_TYPES } from "./FastTable/FastTable";

export default function filterData(filters, data, columns) {
  if (Array.isArray(data) && data.length > 0) {
    let filtered = [...data];
    return filtered.filter((row) => {
      let pass = true;
      filters.forEach((filter) => {
        let passSingle = true;
        if (Array.isArray(filters) && filters.length > 0) {
          const { key, values, operation } = filter;
          if (values.length > 0) {
            passSingle = values.some((value) => {
              let compareValue = value;
              if (!isNaN(value)) {
                compareValue = parseFloat(value);
              }
              const column = columns.find((col) => col.key === key);
              if (typeof column?.filterValue === "function") {
                if (typeof column.filterType === "string") {
                  switch (column.filterType) {
                    case "includes":
                      if (Array.isArray(column.filterValue(row)) &&
                        column.filterValue(row).length) {
                        // console.log("filter()", column.filterValue(row), "===", value) &&
                        // console.log("compare", column.filterValue(row), "includes", value);
                        return column.filterValue(row).includes(value) || column.filterValue(row).includes(compareValue);
                      }
                      break;
                    default: break;
                  }
                } else {
                  return (
                    column.filterValue(row) === value ||
                    column.filterValue(row) === compareValue
                  );
                }
              } else {
                switch (column?.type) {
                  case TABLE_TYPES.dateUnixDate:
                    return (
                      compareValue ===
                      new Date(row[key] * 1000).toLocaleDateString()
                    );
                    break;
                  case TABLE_TYPES.dateUnixTime:
                    return (
                      compareValue ===
                      new Date(row[key] * 1000).toLocaleTimeString()
                    );
                    break;
                  default:
                    break;
                }
              }
              // console.log("filter", row[key], "===", value);
              return row[key] === value || row[key] === compareValue;
            });
            switch (operation) {
              case BOOL_NOT_AND:
                pass = pass && !passSingle;
                break;
              case BOOL_OR:
                pass = pass || passSingle;
                break;
              case BOOL_NOT_OR:
                pass = pass || !passSingle;
                break;
              case BOOL_GT:
                // console.log(
                //   "Greater than",
                //   filter,
                //   row[filter.key],
                //   Math.max(...filter.values)
                // );
                pass = pass && row[filter.key] >= Math.max(...filter.values);
                break;
              case BOOL_ST:
                // console.log(
                //   "Smaller than",
                //   filter,
                //   row[filter.key],
                //   Math.max(...filter.values)
                // );
                pass = pass && row[filter.key] < Math.min(...filter.values);
                break;
              case BOOL_AND:
              default:
                pass = pass && passSingle;
                break;
            }
          }
        }
      });
      return pass;
    });
  }
  return [];
}
