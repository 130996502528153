import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { usePage } from "../../hooks/usePage";
import useLang from "../../lang/useLang";
import { useSummary } from "../../hooks/useReports";
import DateRange from "../../components/FastChart/DateRange";
import Viewer from "../../components/Viewer";
import UpdateReportButton from "../../components/UpdateReportButton";
import useEvents from "../../hooks/useEvents";

export default function Page() {
  const { trans } = useLang();
  usePage({ title: trans("nav.viewer") });
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const params = useParams();
  const eventid = params.eventid;
  const { event } = useEvents();
  const {
    isLoading,
    isRefetching,
    dataUpdatedAt,
    totalTopUpsAmount,
    totalTopUpsPayments,
    totalSales,
    totalSalesAmount,
    noPaymentInfo,
    noPaymentInfoAmount,
    totalInits,
    cashiers,
    salespeople,
    locations,
    products,
    currencies,
    refetch,
  } = useSummary({
    start: dateStart,
    end: dateEnd,
    eventid,
  });

  useEffect(() => {
    if (!dateStart) {
      const urlParams = new URLSearchParams(window.location.search);
      const startParam = urlParams.get("st");
      if (startParam) {
        setDateStart(parseInt(startParam));
      } else if (event?.start) {
        setDateStart(parseInt(event.start));
      }
    }
  }, [event?.start, dateStart]);

  useEffect(() => {
    if (!dateEnd) {
      const urlParams = new URLSearchParams(window.location.search);
      const endParam = urlParams.get("ed");
      if (endParam) {
        setDateEnd(parseInt(endParam));
      } else if (event?.end) {
        setDateEnd(parseInt(event.end));
      }
    }
  }, [event?.end, dateEnd]);

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <h1>{trans("nav.viewer")}</h1>
        </Col>
        <Col className="justify-content-end align-items-center d-flex">
          <UpdateReportButton
            refetch={refetch}
            dataUpdatedAt={dataUpdatedAt}
            isLoading={isLoading}
            isRefetching={isRefetching}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4 text-center" lg={{ offset: 4, span: 4 }}>
          <DateRange
            type="datetime-local"
            start={dateStart}
            end={dateEnd}
            setStart={setDateStart}
            setEnd={setDateEnd}
            options={[
              { value: 60 * 60, label: trans("reports.last_1hr") },
              { value: 60 * 60 * 12, label: trans("reports.last_12hr") },
              { value: 60 * 60 * 24, label: trans("reports.last_24hr") },
              { value: 60 * 60 * 48, label: trans("reports.last_48hr") },
              // { value: 60 * 60 * 24 * 7, label: trans("reports.last_7d") },
              // { value: 60 * 60 * 24 * 30, label: trans("reports.last_30d") },
            ]}
          />
        </Col>
      </Row>
      {!!dateStart ? (
        <>
          <Viewer
            totalTopUpsAmount={totalTopUpsAmount}
            totalTopUpsPayments={totalTopUpsPayments}
            totalSales={totalSales}
            totalSalesAmount={totalSalesAmount}
            totalInits={totalInits}
            cashiers={cashiers}
            salespeople={salespeople}
            locations={locations}
            products={products}
            currencies={currencies}
            refetch={refetch}
            noPaymentInfo={noPaymentInfo}
            noPaymentInfoAmount={noPaymentInfoAmount}
          />
        </>
      ) : (
        <Row>
          <Col>
            <h2 className="text-center">Seleccione un rango de fechas</h2>
          </Col>
        </Row>
      )}
    </Container>
  );
}
