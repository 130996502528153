import { useCallback, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import {
  Pie,
  PieChart,
  Sector,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

export default function FastPieChart({
  data,
  legend,
  dataKey,
  label,
  prefix = "",
  preMap,
  postMap,
  intervals = [],
  palette,
}) {
  const [activeIndex, setActiveIndex] = useState(0);

  const preMappedData = useMemo(() => {
    if (typeof preMap === "function") {
      console.log(
        "premapped data",
        data.map((record) => preMap(record))
      );
      return data.map((record) => preMap(record));
    }
    return data;
  }, [data, preMap]);

  const handleLegendClick = useCallback((entry, index, event) => {
    setActiveIndex(index);
  }, []);

  const postMappedData = useMemo(() => {
    if (typeof postMap === "function") {
      return preMappedData.map((record) => postMap(record));
    }
    return preMappedData;
  }, [preMappedData, postMap]);

  const legendFormatter = (value, entry, index) => {
    const { color } = entry;

    return (
      <span>
        {Array.isArray(legend) && legend[index].label
          ? legend[index].label
          : value}
      </span>
    );
  };

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >
          {payload?.payload?.name}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`${prefix} ${value} (${(percent * 100).toFixed(2)}%)`}
        </text>
      </g>
    );
  };

  const showLegend = postMappedData.length < 30;

  return (
    <>
      <Row>
        <Col>
          <ResponsiveContainer
            width="100%"
            height={
              Math.max(
                250,
                showLegend ? postMappedData.length * 14 : 250
              ) + 100
            }
          >
            <PieChart height={250} width={250}>
              <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={postMappedData}
                dataKey={dataKey}
                outerRadius={125}
                fill="#8884d8"
              >
                {postMappedData.map((entry, index) => (
                  <Cell
                    key={index}
                    fill={palette ? palette[index % palette.length] : "#8884d8"}
                    onClick={() => setActiveIndex(index)}
                  />
                ))}
              </Pie>
              <Tooltip />
              {postMappedData.length < 30 && (
                <Legend formatter={legendFormatter} onClick={handleLegendClick} />)}
            </PieChart>
          </ResponsiveContainer>
        </Col>
      </Row>
    </>
  );
}
