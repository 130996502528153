// usePaginatedQuery.js
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

/**
 * usePaginatedQuery wraps useQuery so that a large overall limit
 * is automatically split into several calls with a per‐call limit of 10,000.
 *
 * Parameters:
 *   queryKey: a unique key for caching
 *   queryFn: a function that accepts an object with { offset, limit }
 *            and returns (a Promise for) a chunk (an array) of items.
 *   limit: overall maximum number of items to fetch. If not provided,
 *          the hook will continue fetching until a chunk returns fewer than
 *          the per-call limit.
 *   ...options: any other react‑query options (such as staleTime, keepPreviousData, etc.)
 *
 * The hook returns everything useQuery returns, plus a `progress` state
 * (which simply counts the number of pages fetched so far).
 */
function usePaginatedQuery({ queryKey, queryFn, limit: overallLimit, ...options }) {
  // progress here is simply the number of pages (chunks) that have been fetched.
  const [progress, setProgress] = useState(0);

  // We always fetch in pages (chunks) of at most this many items.
  const CHUNK_SIZE = 10000;
  // overallLimit: if not provided, we assume we keep fetching until a page returns less than CHUNK_SIZE.
  const totalLimit = overallLimit !== undefined ? overallLimit : Infinity;

  // This is the function that useQuery will call. It runs sequentially.
  const paginatedQueryFn = async () => {
    // Reset progress when starting a (re)fetch.
    setProgress(0);

    let offset = 0;
    let allResults = [];

    // Continue fetching pages until one returns fewer than requested or until we have enough.
    while (allResults.length < totalLimit) {
      // Determine the per-call limit: for the last page we might need fewer than CHUNK_SIZE.
      const remaining = totalLimit - allResults.length;
      const currentLimit = isFinite(totalLimit) ? Math.min(CHUNK_SIZE, remaining) : CHUNK_SIZE;

      // Call the provided queryFn with the current offset and per-call limit.
      // (Your API endpoint should accept offset and limit.)
      console.log("queryFn: " + offset + " - " + currentLimit);
      const chunk = await queryFn({ offset, limit: currentLimit });

      // Update progress (each page fetched adds 1)
      setProgress((prev) => prev + 1);

      // Merge the newly fetched items with those from previous pages.
      allResults = allResults.concat(chunk);

      console.log("allResults length: " + allResults.length);
      console.log("Chunk length: " + chunk.length);
      console.log("totalLimit: " + totalLimit);
      // If the chunk is smaller than requested, there is no more data.
      if (chunk.length < currentLimit || chunk.length === 0) {
        break;
      }

      // Prepare the offset for the next page.
      offset += chunk.length;
      console.log("offset: " + offset);
    }
    return allResults;
  };

  // We pass our custom paginated query function to useQuery.
  // (We also pass through any additional options – including keepPreviousData.)
  const queryResult = useQuery({
    queryKey,
    queryFn: paginatedQueryFn,
    // Ensure keepPreviousData is enabled unless the caller has explicitly set it.
    keepPreviousData: options.keepPreviousData ?? true,
    ...options,
  });

  // Return everything from useQuery plus our progress state.
  return { ...queryResult, progress };
}

export default usePaginatedQuery;
