import { Col, Container, Row, Button } from "react-bootstrap";
// import fakeRecords from "../../data/fake-records";
import FastTable, { TABLE_TYPES } from "../../components/FastTable/FastTable";
import useCurrencies from "../../hooks/useCurrencies";
import { usePage } from "../../hooks/usePage";
import { useParams, useSearchParams } from "react-router-dom";
import { useSales } from "../../hooks/useReports";
import { TX_TYPES } from "../../constants";
import DateRange from "../../components/FastChart/DateRange";
import { useCallback, useEffect, useState } from "react";
import useLang from "../../lang/useLang";
import SingleTagTxs from "../../components/SingleTagTxs";
import styles from "./reports.module.scss";
import UpdateReportButton from "../../components/UpdateReportButton";
import useEvents from "../../hooks/useEvents";

export default function ReportsSales() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { trans } = useLang();
  usePage({ title: trans("nav.sales") });
  const [selectedTag, setSelectedTag] = useState(null);
  const [dateStart, setDateStart] = useState();
  const [dateEnd, setDateEnd] = useState();
  const params = useParams();
  const eventid = params.eventid;
  const { event } = useEvents();
  const { currencies } = useCurrencies();
  const { sales, isLoading, isRefetching, dataUpdatedAt, refetch } = useSales({
    eventid,
    limit: 1000000,
    offset: 0,
    start: dateStart,
    end: dateEnd,
  });

  const handleSelectTag = useCallback(
    (tag) => {
      // update local search params with tag = tag
      console.log("set tag", searchParams.toString(), tag);
      if (tag) {
        searchParams.set("tag", tag);
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams]
  );

  useEffect(() => {
    console.log(
      "update local state with search params",
      searchParams.toString()
    );
    if (searchParams.has("tag")) {
      setSelectedTag(searchParams.get("tag"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (!dateStart) {
      const urlParams = new URLSearchParams(window.location.search);
      const startParam = urlParams.get("st");
      if (startParam) {
        setDateStart(parseInt(startParam));
      } else if (event?.start) {
        setDateStart(parseInt(event.start));
      }
    }
  }, [event?.start, dateStart]);

  useEffect(() => {
    if (!dateEnd) {
      const urlParams = new URLSearchParams(window.location.search);
      const endParam = urlParams.get("ed");
      if (endParam) {
        setDateEnd(parseInt(endParam));
      } else if (event?.end) {
        setDateEnd(parseInt(event.end));
      }
    }
  }, [event?.end, dateEnd]);

  return (
    <Container fluid={true}>
      <Row>
        <Col>
          <h1>{trans("reports.sales")}</h1>
        </Col>
        <Col className="justify-content-end align-items-center d-flex">
          <UpdateReportButton
            refetch={refetch}
            dataUpdatedAt={dataUpdatedAt}
            isLoading={isLoading}
            isRefetching={isRefetching}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mb-4 text-center" lg={{ offset: 4, span: 4 }}>
          <DateRange
            type="datetime-local"
            start={dateStart}
            end={dateEnd}
            setStart={setDateStart}
            setEnd={setDateEnd}
            options={[
              { value: 60 * 60, label: trans("reports.last_1hr") },
              { value: 60 * 60 * 12, label: trans("reports.last_12hr") },
              { value: 60 * 60 * 24, label: trans("reports.last_24hr") },
              { value: 60 * 60 * 48, label: trans("reports.last_48hr") },
              // { value: 60 * 60 * 24 * 7, label: trans("reports.last_7d") },
              // { value: 60 * 60 * 24 * 30, label: trans("reports.last_30d") },
            ]}
          />
        </Col>
      </Row>
      {!!dateStart ? (
        <Row>
          <Col>
            <FastTable
              data={sales}
              chart={{
                preMap: (record, interval) => {
                  return {
                    ...record,
                    load:
                      parseInt(record.amount) > 0 ? parseInt(record.amount) : 0,
                    sale:
                      parseInt(record.amount) < 0
                        ? -parseInt(record.amount)
                        : 0,
                    recordCount: 1,
                  };
                },
                postMap: (record, interval) => {
                  return {
                    ...record,
                    dateStr: new Date(
                      Math.floor(record.date / interval) * interval * 1000
                    ).toLocaleString(),
                  };
                },
                // dateRange: { dataKey: "date", type: "datetime-local" },
                xAxis: {
                  dataKey: "dateStr",
                  label: "Fecha",
                },
                intervals: [
                  {
                    key: "minute",
                    interval: 60,
                    label: "Minuto",
                    dataKey: "date",
                  },
                  {
                    key: "5m",
                    interval: 5 * 60,
                    label: "5 min.",
                    dataKey: "date",
                    isDefault: true,
                  },
                  {
                    key: "15m",
                    interval: 15 * 60,
                    label: "15 min.",
                    dataKey: "date",
                  },
                  {
                    key: "30m",
                    interval: 30 * 60,
                    label: "30 min.",
                    dataKey: "date",
                  },
                  {
                    key: "hour",
                    interval: 60 * 60,
                    label: "Hora",
                    dataKey: "date",
                  },
                  {
                    key: "day",
                    interval: 24 * 60 * 60,
                    label: "Día",
                    dataKey: "date",
                  },
                ],
                yAxis: [
                  {
                    dataKey: "load",
                    // type: TABLE_TYPES.currency,
                    name: "Venta",
                    fill: "#40d080",
                    stroke: "#40d080",
                    unit: " $",
                    hide: false,
                  },
                  {
                    dataKey: "sale",
                    // type: TABLE_TYPES.currency,
                    name: "Devolución",
                    fill: "#d04080",
                    stroke: "#d04080",
                    unit: " $",
                    hide: false,
                  },
                  {
                    dataKey: "recordCount",
                    // type: TABLE_TYPES.currency,
                    name: "Transacciones",
                    fill: "#4080d0",
                    stroke: "#4080d0",
                    unit: " x",
                    hide: false,
                  },
                ],
              }}
              columns={[
                {
                  key: "txid",
                  label: "ID",
                  filter: true,
                  aggregate: (acc) => acc + 1,
                  aggregateInitialValue: 1,
                },
                {
                  key: "offtxid",
                  label: "ID local",
                  filter: true,
                },
                {
                  key: "date",
                  label: "Fecha",
                  filter: true,
                  type: TABLE_TYPES.dateUnixDate,
                },
                {
                  key: "date-time",
                  sourceKey: "date",
                  label: "Hora",
                  filter: false,
                  groupBy: false,
                  type: TABLE_TYPES.dateUnixTime,
                },
                {
                  key: "currencyid",
                  label: "Moneda",
                  filter: true,
                  type: TABLE_TYPES.options,
                  options: currencies.map((currency) => ({
                    value: currency.currencyid,
                    label: currency.name,
                  })),
                },
                {
                  key: "amount",
                  label: "Monto",
                  filter: true,
                  type: TABLE_TYPES.currency,
                  prefix: "$",
                  aggregateInitialValue: "0, $ 0 = $ 0",
                  aggregate: (_acc, row) => {
                    console.log("acc", _acc, row?.amount);
                    const firstSplit = _acc.split(", $ ");
                    const secondSplit = firstSplit[1].split(" = $ ");
                    const splitted = [
                      parseInt(firstSplit[0]),
                      parseInt(secondSplit[0]),
                      parseInt(secondSplit[1]),
                    ];
                    if (parseInt(row?.amount) > 0) {
                      splitted[0] += parseInt(row.amount);
                    } else {
                      splitted[1] += parseInt(row.amount);
                    }
                    splitted[2] = splitted[0] + splitted[1];
                    return `${splitted[0]}, $ ${splitted[1]} = $ ${splitted[2]}`;
                  },
                },
                {
                  key: "products",
                  label: "Items",
                  type: TABLE_TYPES.string,
                  render: (row) =>
                    Array.isArray(row?.products)
                      ? row?.products
                        ?.map(
                          (product) =>
                            `${product.name
                              ? product.name
                              : `#${product.productid}`
                            } x${product.qty}`
                        )
                        .join(", ")
                      : "",
                },
                {
                  key: "tag",
                  filter: true,
                  label: "Tag",
                  type: TABLE_TYPES.string,
                  render: (row) => (
                    <span
                      className={styles.TableLink}
                      onClick={() => handleSelectTag(row.tag)}
                    >
                      {row.tag}
                    </span>
                  ),
                },
                {
                  key: "userName",
                  filter: true,
                  label: "Operador",
                  type: TABLE_TYPES.string,
                },
                {
                  key: "deviceshorttag",
                  label: "Terminal",
                  type: TABLE_TYPES.string,
                  render: (row) => `${row.deviceName} (${row.deviceshorttag})`,
                },
                {
                  key: "type",
                  filter: true,
                  label: "Tipo",
                  type: TABLE_TYPES.options,
                  options: Object.values(TX_TYPES),
                },
              ]}
            />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <h2 className="text-center">Seleccione un rango de fechas</h2>
          </Col>
        </Row>
      )}
      {selectedTag && (
        <Row>
          <Col>
            <SingleTagTxs
              tag={selectedTag}
              start={dateStart}
              end={dateEnd}
              eventid={eventid}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
}
