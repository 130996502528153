import { useNavigate, useParams } from "react-router-dom";
import FastForm from "../../components/FastForm/FastForm";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import useUsers from "../../hooks/useUsers";
import { useMemo, useState } from "react";
import FastTable, { TABLE_TYPES } from "../../components/FastTable/FastTable";
import { usePage } from "../../hooks/usePage";
import useCurrencies from "../../hooks/useCurrencies";
import useEvents from "../../hooks/useEvents";
import useLang from "../../lang/useLang";
import useDevices from "../../hooks/useDevices";

export default function SearchUser() {
  const { trans } = useLang();
  const params = useParams();
  const navigate = useNavigate();
  const [filter, setFilter] = useState({});
  const usersQuery = useUsers({
    offset: 0,
    limit: 1000000,
    filter: filter,
    enabled: Object.keys(filter).length > 0,
  });
  usePage({ title: "Buscar usuario" });

  const { currencies } = useCurrencies();
  const { data: events } = useEvents({ offset: 0, limit: 1000000 });
  const { devices } = useDevices({ eventid: params.eventid });

  const fakeMutation = useMemo(
    () => ({
      mutate: (data) => {
        console.log(Object.keys(data));
        console.log(Object.keys(data).filter((key) => data[key] !== ""));
        console.log(
          Object.keys(data)
            .filter((key) => data[key] !== "")
            .reduce((acc, key) => ({ ...acc, [key]: data[key] }), {})
        );
        const filterKeys = Object.keys(data).filter((key) => data[key] !== "");
        const reducedFilter = filterKeys.reduce(
          (acc, key) => ({ ...acc, [key]: data[key] }),
          {}
        );
        setFilter(reducedFilter);
      },
      ...usersQuery,
    }),
    [usersQuery]
  );

  const splittedUsers = useMemo(() => {
    if (usersQuery?.data?.length > 0) {
      return usersQuery.data.reduce((acc, user) => {
        for (let i = 1; i <= 3; i++) {
          const splittedUser = { ...user };
          splittedUser[`balance`] = user[`balance${i}`];
          splittedUser[`currencyid`] = user[`currencyid${i}`];
          splittedUser[`date`] = user[`date${i}`];
          splittedUser[`operator`] = user[`userid${i}`];
          splittedUser[`deviceid`] = user[`deviceid${i}`];
          acc.push(splittedUser);
        }
        return acc;
      }, []);
    }
    return [];
  }, [usersQuery.data]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <h1>Buscar usuario</h1>
          <hr className="small" />
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <h2>Buscar usuario</h2>
          <FastForm
            mutation={fakeMutation}
            sendText="Buscar"
            fields={[
              {
                name: "name",
                label: "Nombre",
                placeholder: "Nombre",
                type: "text",
              },
              {
                name: "email",
                label: "Email",
                placeholder: "Email",
                type: "email",
              },
              {
                name: "tag",
                label: "Tag",
                placeholder: "Tag",
                type: "text",
              },
              // {
              //   name: "orgid",
              //   label: "Organización",
              //   placeholder: "Organización",
              //   type: "hidden",
              //   attributes: { readOnly: true },
              //   defaultValue: user.orgid,
              // },
            ]}
          />
        </Col>
        <Col md={9}>
          <h2>Resultado</h2>
          {usersQuery.isLoading && <Spinner animation="border" />}
          {usersQuery.data?.length > 0 && (
            <FastTable
              data={splittedUsers}
              onSelectRow={(row) =>
                navigate(`/${params.eventid}/users/${row.userid}`)
              }
              showExcel={false}
              showGroups={false}
              showFilters={false}
              columns={[
                { key: "userid", label: "userid", type: TABLE_TYPES.string },
                { key: "name", label: "name", type: TABLE_TYPES.string },
                { key: "email", label: "email", type: TABLE_TYPES.string },
                { key: "tag", label: "tag", type: TABLE_TYPES.string },
                { key: "shortag", label: "shortag", type: TABLE_TYPES.string },
                { key: "repair", label: "repair", type: TABLE_TYPES.string },
                { key: "block", label: "block", type: TABLE_TYPES.string },
                {
                  key: "eventid",
                  label: "Evento",
                  type: TABLE_TYPES.options,
                  options: [
                    {
                      value: "0",
                      label: trans("devices.chooseevent"),
                    },
                    ...(events || []).map((event) => ({
                      value: event.eventid,
                      label: event.name,
                    })),
                  ],
                },
                {
                  key: "balance",
                  label: "Monto",
                  prefix: "$ ",
                  type: TABLE_TYPES.currency,
                },
                {
                  key: "currencyid",
                  label: "Moneda",
                  type: TABLE_TYPES.options,
                  options: [
                    { value: "", label: "Elija la moneda" },
                    ...(currencies?.map((currency) => ({
                      value: currency.currencyid,
                      label: currency.name,
                    })) || []),
                  ],
                },
                {
                  key: "date",
                  label: "Últ.tx",
                  type: TABLE_TYPES.dateUnix,
                },
                {
                  key: "operator",
                  label: "Oper.",
                  type: TABLE_TYPES.string,
                },
                {
                  key: "deviceid",
                  label: "Terminal",
                  type: TABLE_TYPES.options,
                  options: [
                    {
                      value: "0",
                      label: "desconocido",
                    },
                    ...(devices || []).map((device) => ({
                      value: device.deviceid,
                      label: `${device.name} (${device.deviceshorttag})`,
                    })),
                  ],
                },
              ]}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
}
