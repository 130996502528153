import { Col, Container, Row } from "react-bootstrap";
import useLang from "../../lang/useLang";
import { useParams } from "react-router-dom";
import { usePage } from "../../hooks/usePage";
import { useEffect, useState } from "react";
import TagSearchPublic from "../../components/TagSearchPublic";
import DateRange from "../../components/FastChart/DateRange";
import FastTable from "../../components/FastTable/FastTable";
import { TABLE_TYPES } from "../../components/FastTable/FastTable";
import { TX_TYPES } from "../../constants";
import useSearchParamState from "../../hooks/useSearchParamState";
import { usePublicSummary } from "../../hooks/usePublicSummary";

export default function PublicTransactions() {
  const { trans } = useLang();
  const { rtoken } = useParams();
  usePage({ title: trans("reports.transactions") });
  const [dateStart, setDateStart] = useSearchParamState("st");
  const [dateEnd, setDateEnd] = useSearchParamState("ed");
  const [tag, setTag] = useState("");

  const {
    event,
    dataUpdatedAt,
    allLocations,
    refetch,
    isLoading,
    isRefetching,
    validData,
    pin,
    setPin,
    dataQuery,
    dataStatus,
    dataError,
    currencies,
    paymentMethods,
    data: transactions,
    locations,
    users,
    devices,
  } = usePublicSummary({
    start: dateStart,
    end: dateEnd,
    limit: 1000000000,
    offset: 0,
    rtoken,
  });
  const eventid = event?.eventid;

  useEffect(() => {
    //check url for search query if tag is set
    const urlParams = new URLSearchParams(window.location.search);
    const tagParam = urlParams.get("tag");
    if (tagParam) {
      setTag(tagParam);
    }
  }, []);

  useEffect(() => {
    // set tag search query in url if tag is set
    if (tag && tag !== "") {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set("tag", tag);
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${urlParams}`
      );
    }
  }, [tag]);

  return (
    <Container fluid={true}>
      <TagSearchPublic
        eventid={eventid}
        start={dateStart}
        end={dateEnd}
        tag={tag}
        setTag={setTag}
        currencies={currencies}
        paymentMethods={paymentMethods}
        locations={locations}
        users={users}
        devices={devices}
        transactions={transactions}
      />
      <Row>
        <Col className="mb-4 text-center" lg={{ offset: 4, span: 4 }}>
          <DateRange
            type="datetime-local"
            start={dateStart}
            end={dateEnd}
            setStart={setDateStart}
            setEnd={setDateEnd}
            options={[
              { value: 60 * 60, label: trans("reports.last_1hr") },
              { value: 60 * 60 * 12, label: trans("reports.last_12hr") },
              { value: 60 * 60 * 24, label: trans("reports.last_24hr") },
              { value: 60 * 60 * 48, label: trans("reports.last_48hr") },
            ]}
          />
        </Col>
      </Row>
      {!!dateStart ? (
        <FastTable
          data={transactions}
          columns={[
            { key: "txid", label: "txid", type: TABLE_TYPES.string },
            { key: "offtxid", label: "offtxid", type: TABLE_TYPES.string },
            { key: "tag", label: "tag", type: TABLE_TYPES.number },
            {
              key: "date",
              label: "Fecha",
              filter: true,
              type: TABLE_TYPES.dateUnixDate,
            },
            {
              key: "date-time",
              sourceKey: "date",
              label: "Hora",
              filter: false,
              groupBy: false,
              type: TABLE_TYPES.dateUnixTime,
            },
            {
              key: "type",
              filter: true,
              label: "Tipo",
              type: TABLE_TYPES.options,
              options: Object.values(TX_TYPES),
            },
            {
              key: "currencyid",
              label: "Moneda",
              filter: true,
              type: TABLE_TYPES.options,
              options: (Array.isArray(currencies) ? currencies : []).map((currency) => ({
                value: currency.currencyid,
                label: currency.name,
              })),
            },
            {
              key: "amount",
              label: "amount",
              type: TABLE_TYPES.currency,
              prefix: "$ ",
              aggregateInitialValue: "0, $ 0 = $ 0",
              aggregate: (_acc, row) => {
                console.log("acc", _acc, row?.amount);
                const firstSplit = _acc.split(", $ ");
                const secondSplit = firstSplit[1].split(" = $ ");
                const splitted = [
                  parseInt(firstSplit[0]),
                  parseInt(secondSplit[0]),
                  parseInt(secondSplit[1]),
                ];
                if (parseInt(row?.amount) > 0) {
                  splitted[0] += parseInt(row.amount);
                } else {
                  splitted[1] += parseInt(row.amount);
                }
                splitted[2] = splitted[0] + splitted[1];
                return `${splitted[0]}, $ ${splitted[1]} = $ ${splitted[2]}`;
              },
            },
            {
              key: "products",
              label: "Items",
              type: TABLE_TYPES.string,
              render: (row) =>
                Array.isArray(row?.products)
                  ? row?.products
                    ?.map(
                      (product) =>
                        `${product.name
                          ? product.name
                          : `#${product.productid}`
                        } x${product.qty}`
                    )
                    .join(", ")
                  : "",
            },
            {
              key: "paymentmethodid",
              filter: true,
              label: trans("reports.paymentmethod"),
              type: TABLE_TYPES.options,
              options: (Array.isArray(paymentMethods) ? paymentMethods : []).map((paymentmethod) => ({
                value: paymentmethod.paymentmethodid,
                label: paymentmethod.name,
              })),
            },
            {
              key: "locationid",
              label: "Ubicaciones",
              type: TABLE_TYPES.options,
              options: (Array.isArray(locations) ? locations : []).map((location) => ({
                value: location.locationid,
                label: location.name + " (" + location.locationid + ")",
              })),
            },
            {
              key: "deviceshorttag",
              label: "Terminal",
              type: TABLE_TYPES.string,
              render: (row) =>
                `${row.deviceName ? row.deviceName : "-"} (${row.deviceshorttag
                })`,
            },
            { key: "userName", label: "userName", type: TABLE_TYPES.string, filter: true },
          ]}
        />
      ) : (
        <Row>
          <Col>
            <h2 className="text-center">Seleccione un rango de fechas</h2>
          </Col>
        </Row>
      )}
    </Container>
  );
}