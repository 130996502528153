import { Col, Container, Row, Spinner } from "react-bootstrap";
import useUsers from "../../hooks/useUsers";
import FastTable, { TABLE_TYPES } from "../../components/FastTable/FastTable";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import { usePage } from "../../hooks/usePage";
import { useAuth } from "../../hooks/useAuth";
import useEvents from "../../hooks/useEvents";
import { useMemo } from "react";

export default function Users() {
  const { data: events } = useEvents();
  const { eventid } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useUsers({ eventid });
  const isUsersHome = useMatch("/:eventid/users");
  const isAllUsers = useMatch("/users");
  usePage({ title: "Usuarios" });

  const columns = useMemo(() => {
    const columns = [
      {
        key: "userid",
        label: "ID",
        sort: true,
        filter: true,
        type: TABLE_TYPES.string,
      },
      {
        key: "name",
        label: "Name",
        sort: true,
        filter: true,
        type: TABLE_TYPES.string,
      },
      {
        key: "email",
        label: "Email",
        sort: true,
        filter: true,
        type: TABLE_TYPES.string,
      },
      {
        key: "tag",
        label: "Tag",
        sort: true,
        filter: true,
        type: TABLE_TYPES.string,
      },
      {
        key: "shortag",
        label: "Short Tag",
        sort: true,
        filter: true,
        type: TABLE_TYPES.string,
      },
      {
        key: "balance1",
        label: "Balance 1",
        sort: true,
        filter: false,
        type: TABLE_TYPES.currency,
        prefix: "$ ",
      },
      {
        key: "balance2",
        label: "Balance 2",
        sort: true,
        filter: false,
        type: TABLE_TYPES.currency,
        prefix: "$ ",
      },
      {
        key: "balance3",
        label: "Balance 3",
        sort: true,
        filter: false,
        type: TABLE_TYPES.currency,
        prefix: "$ ",
      },
      { key: "repair", label: "Reparar", filter: true, sort: true, type: TABLE_TYPES.string, render: (row) => row.repair === "1" ? "Si" : "No" },
      { key: "block", label: "Bloqueado", filter: true, sort: true, type: TABLE_TYPES.string, render: (row) => row.block === "1" ? "Si" : "No" },
    ];
    if (!eventid) {
      columns.push({
        key: "eventid",
        label: "Evento",
        sort: true,
        filter: true,
        type: TABLE_TYPES.options,
        options: events?.map((e) => ({ label: e.name, value: e.eventid })),
      });
    }
    return columns;
  }, [eventid, events]);

  if (!isUsersHome && !isAllUsers) return <Outlet />;

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <h1>Usuarios</h1>
            {isLoading && <Spinner animation="border" />}
          </Col>
        </Row>
      </Container>
      <FastTable
        data={data}
        showGroups={false}
        columns={columns}
        onSelectRow={(row) => navigate(`/${row.eventid}/users/${row.userid}`)}
        cellStyle={(row) => (row?.block === "1" ? { backgroundColor: "#f0a0a0", color: "black" } : (row.repair === "1" ? { backgroundColor: "#f0e8a0", color: "black" } : {}))}
      />
    </>
  );
}
